import { memo } from 'react';
import { Col, OverlayTrigger, Stack, Tooltip, Row } from 'react-bootstrap';
import { FaInfoCircle, FaSort, FaSortDown, FaSortUp } from 'react-icons/fa';
import { useOutletContext } from 'react-router-dom';
import HomeTableClientRow from './HomeTableClientRow';
import InfiniteScroll from 'react-infinite-scroll-component';
import { useSelector } from 'react-redux';

const HomeTableView = () => {
  const { processSorting, infiniteClientsList } = useOutletContext();

  const {
    isLoading, isError, clients,
    clientsSorting, isLoadingAllClients, activeHomeTab
  } = useSelector(state => state.home);

  const handleClickSortBy = (field) => {
    let sortField, sortType;

    if (clientsSorting.field === field) {
      if (clientsSorting.type === 'ASC') {
        sortField = field;
        sortType = 'DESC';
      } else {
        sortField = null;
        sortType = null;
      }
    } else {
      sortField = field;
      sortType = 'ASC';
    }

    processSorting({ field: sortField, type: sortType });
  };

  const getOrderIcon = (field) => {
    return clientsSorting.field === field ? (
      clientsSorting.type === 'ASC' ? (
        <FaSortUp />
      ) : (
        <FaSortDown />
      )
    ) : (
      <FaSort />
    );
  };

  const getStatusMessage = () => {
    if (isLoading || isLoadingAllClients) return 'Loading ...';
    if (isError)
      return (
        <>
          Something went wrong, please
          <br />
          <span
            role='button'
            className='link'
            onClick={() => window.location.reload()}
          >
            reload the page
          </span>
        </>
      );
    if (clients.length === 0)
      return 'We did not find any results, please try with different filters.';
  };

  return (
    <Row className='mx-0 p-0 gx-0'>
      <Stack
        direction='horizontal'
        className='py-3 text-uppercase bg-gray border-top border-gray-lighter fw-bold fs-12 hoverable py-2 ps-2'
      >
        <Col xs={2}>Client</Col>
        <Col xs={1}>Platform ID</Col>
        <Col xs={2}>
          <Stack direction='horizontal' gap={1}>
            <Stack
              direction='horizontal'
              role='button'
              onClick={() => handleClickSortBy('compliantVolume')}
            >
              {getOrderIcon('compliantVolume')}
            </Stack>
            Compliant Volume
            <OverlayTrigger
              placement={'bottom'}
              overlay={
                <Tooltip id={`tooltip-compliant-volume`}>
                  Purchase $ Volume of items on a MAP Contract and/or Rebate
                </Tooltip>
              }
            >
              <Stack direction='horizontal' role='button'>
                <FaInfoCircle color='var(--bs-primary)' />
              </Stack>
            </OverlayTrigger>
          </Stack>
        </Col>
        <Col xs={2}>
          <Stack direction='horizontal' gap={1}>
            <Stack
              direction='horizontal'
              role='button'
              onClick={() => handleClickSortBy('nonCompliantVolume')}
            >
              {getOrderIcon('nonCompliantVolume')}
            </Stack>
            Non compliant volume
            <OverlayTrigger
              placement={'bottom'}
              delay={{ show: 0, hide: 0 }}
              overlay={
                <Tooltip id={`tooltip-non-compliant-volume`}>
                  Purchase $ Volume of items NOT on a MAP Contract and/or Rebate
                </Tooltip>
              }
            >
              <Stack direction='horizontal' role='button'>
                <FaInfoCircle color='var(--bs-primary)' />
              </Stack>
            </OverlayTrigger>
          </Stack>
        </Col>
        <Col xs={2}>
          <Stack direction='horizontal' gap={1}>
            <Stack
              direction='horizontal'
              role='button'
              onClick={() => handleClickSortBy('compliantVolumePercentage')}
            >
              {getOrderIcon('compliantVolumePercentage')}
            </Stack>
            Compliant volume %
            <OverlayTrigger
              placement={'bottom'}
              overlay={
                <Tooltip id={`tooltip-compliant-volume-%`}>
                  Ratio of Compliant $ Volume / Total Purchase $ Volume
                </Tooltip>
              }
            >
              <Stack direction='horizontal' role='button'>
                <FaInfoCircle color='var(--bs-primary)' />
              </Stack>
            </OverlayTrigger>
          </Stack>
        </Col>
        <Col xs={1} className='pe-3'>
          <Stack direction='horizontal' gap={1}>
            <Stack
              direction='horizontal'
              role='button'
              onClick={() => handleClickSortBy('numberOfNonCompliantMins')}
            >
              {getOrderIcon('numberOfNonCompliantMins')}
            </Stack>
            # of Non Compliant MINs
            <OverlayTrigger
              placement={'bottom'}
              overlay={
                <Tooltip id={`tooltip-non-compliant`}>
                  Count of Unique Manufacturer Products purchased NOT on a MAP Contract and/or Rebate
                </Tooltip>
              }
            >
              <Stack direction='horizontal' role='button'>
                <FaInfoCircle color='var(--bs-primary)' />
              </Stack>
            </OverlayTrigger>
          </Stack>
        </Col>
        <Col xs={1}>
          <Stack direction='horizontal' gap={1}>
            <Stack
              direction='horizontal'
              role='button'
              onClick={() => handleClickSortBy('subscriptionLevel')}
            >
              {getOrderIcon('subscriptionLevel')}
            </Stack>
            Subscription Level
            <OverlayTrigger
              placement={'bottom'}
              overlay={
                <Tooltip id={`tooltip-subscription-level`}>
                  Account Subscription Level from Salesforce for MAP Contracts & Rebate Billing
                </Tooltip>
              }
            >
              <Stack direction='horizontal' role='button'>
                <FaInfoCircle color='var(--bs-primary)' />
              </Stack>
            </OverlayTrigger>
          </Stack>
        </Col>
      </Stack>
      {(!isLoading && !isError && clients.length > 0) ? (
        <InfiniteScroll {...infiniteClientsList}>
          {clients.map((item) => (
            <HomeTableClientRow {...item} key={`table_${item.client}`} activeHomeTab={activeHomeTab} />
          ))}
        </InfiniteScroll>
      ) : (
        <div className='text-center p-2'>{getStatusMessage()}</div>
      )}
    </Row>
  );
};
export default memo(HomeTableView);
