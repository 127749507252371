import Axios from 'axios';

// eslint-disable-next-line no-undef
const url = `${process.env.REACT_APP_API_URL}/client-manager`;

const clientApi = {
  getClientUsers: () => {
    return Axios.post(`${url}`);
  }
};

export default clientApi;
