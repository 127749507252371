import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  clientUsers: [],
  clientUsersSelected: []
};

const clientSlice = createSlice({
  name: 'client',
  initialState,
  reducers: {
    setClientUsers: (state, action) => {
      state.clientUsers = action.payload;
    },
    setClientUsersSelected: (state, action) => {
      state.clientUsersSelected = action.payload;
    }
  },
});

export const { setClientUsers, setClientUsersSelected } = clientSlice.actions;
export default clientSlice.reducer;
