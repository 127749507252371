import { memo } from 'react';
import { Col, Stack, Button } from 'react-bootstrap';
import { FaSort, FaSortDown, FaSortUp, FaChevronDown } from 'react-icons/fa';
import InfiniteScroll from 'react-infinite-scroll-component';
import { useSelector } from 'react-redux';
import SimilarItemsTableRow from './SimilarItemsTableRow';

const SimilarItemsTable = (props) => {
  const { processSorting, handleClickExportButton, merchant } = props;
  const { similarItems, similarItemsSorting, isLoading, isError } = useSelector( state => state.homeSimilarItems);

  const handleClickSortBy = (field) => {
    let sortField, sortType;

    if (similarItemsSorting.field === field) {
      if (similarItemsSorting.type === 'ASC') {
        sortField = field;
        sortType = 'DESC';
      } else {
        sortField = null;
        sortType = null;
      }
    } else {
      sortField = field;
      sortType = 'ASC';
    }

    processSorting({ field: sortField, type: sortType });
  };

  const getOrderIcon = (field) => {
    return similarItemsSorting.field === field ? (
      similarItemsSorting.type === 'ASC' ? (
        <FaSortUp />
      ) : (
        <FaSortDown />
      )
    ) : (
      <FaSort />
    );
  };

  const getStatusMessage = () => {
    if (isLoading) return 'Loading ...';
    if (isError)
      return (
        <>
          Something went wrong, please
          <br />
          <span
            role='button'
            className='link'
            onClick={() => window.location.reload()}
          >
            reload the page
          </span>
        </>
      );
    if (similarItems.length === 0)
      return 'We did not find any results, please try with another item.';
  };

  const infinite = {
    dataLength: similarItems.length,
    next: () => {},
    hasMore: false,
    scrollThreshold: 1,
    loader: <div className='text-center p-2 border-top text-gray-text'>Loading ...</div>,
    endMessage: <div className='text-center p-2 border-top text-gray-text'>No more items</div>
  };

  return (
    <div className='mt-2 border border-gray-light bg-white shadow-box'>
      <Stack className='fs-16 fw-bold m-2' direction='horizontal'>
        <Col>Similiar Items</Col>
        <Col className='mw-fc'>
          <Button variant='primary' style={{width: '83px'}} onClick={handleClickExportButton}>
            Export <FaChevronDown />
          </Button>
        </Col>
      </Stack>
      <Stack
        direction='horizontal'
        className='py-3 text-uppercase bg-gray border-top border-gray-lighter fw-bold fs-12 py-2 ps-2'
      >
        <Col xs={1}>Min</Col>
        <Col xs={2}>Item Description</Col>
        <Col xs={2}>Manufacturer</Col>
        <Col xs={2}>Brand</Col>
        <Col xs={1}>
          <Stack direction='horizontal' gap={1}>
            <Stack
              direction='horizontal'
              role='button'
              onClick={() => handleClickSortBy('packsize')}
            >
              {getOrderIcon('packsize')}
            </Stack>
            Pack size
          </Stack>
        </Col>
        <Col xs={1}>
          <Stack direction='horizontal' gap={1}>
            <Stack
              direction='horizontal'
              role='button'
              onClick={() => handleClickSortBy('quantity')}
            >
              {getOrderIcon('quantity')}
            </Stack>
            Switch quantity
          </Stack>
        </Col>
        <Col xs={1}>Annual Rebate</Col>
        <Col xs={1}>Deviation Savings</Col>
        <Col xs={1}>Action</Col>
      </Stack>
      {(!isLoading && !isError && similarItems.length > 0) ? (
        <InfiniteScroll {...infinite}>
          {similarItems.map((item, index) => (
            <SimilarItemsTableRow merchant={merchant} item={item} key={index} />
          ))}
        </InfiniteScroll>
      ) : (
        <div className='text-center p-2 bg-white'>{getStatusMessage()}</div>
      )}
    </div>
  );
};

export default memo(SimilarItemsTable);
