import { Form, Button, Row, Col } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';

const RegisterForm = () => {

  const navigate = useNavigate();

  const goToLogin = () => {
    navigate('/');
  };

  return (
    <Form onSubmit={()=>{}}>
      <Form.Group>
        <Form.Label htmlFor='contacName' className='fs-5 text-gray-text'>Contact Name*</Form.Label>
        <Form.Control id='contacName' className='shadow-none' />
      </Form.Group>
      <Form.Group>
        <Form.Label htmlFor='email' className='fs-5 text-gray-text'>Email*</Form.Label>
        <Form.Control id='email'  className='shadow-none' />
      </Form.Group>
      <Form.Group>
        <Form.Label htmlFor='password' className='fs-5 text-gray-text'>Password*</Form.Label>
        <Form.Control id='password' className='shadow-none' />
      </Form.Group>
      <Form.Group>
        <Form.Label htmlFor='confirmPassword' className='fs-5 text-gray-text'>Confirm Password*</Form.Label>
        <Form.Control id='confirmPassword'  className='shadow-none' />
      </Form.Group>
      <Form.Group className='pt-4'>
        <Button variant='secondary' className='shadow-none w-100'>Sign Up</Button>
      </Form.Group>
      <Row className='mb-5'>
        <Col className='text-center clickable' onClick={goToLogin}>
          <span className='fs-5 text-gray-text'>Already have an account?</span> &nbsp;
          <span className='text-secondary'>Login</span>
        </Col>
      </Row>
    </Form>
  )
}

export default RegisterForm;