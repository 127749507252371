import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams, useLocation } from 'react-router-dom';
import generalApi from '../../api/generalApi';
import homeApi from '../../api/homeApi';
import {
  clearClientDetailsItems,
  resetClientDetailsState,
  setClientDetailsItemsError,
  setMyClientDetailsItems,
  setSelectedClient,
  setSelectedPlatform,
  updateClientDetailsItems,
  setOverview,
  clearOverview,
} from '../../Reducers/home/homeClientDetailsSlice';
import { setSpinner } from '../../Reducers/ui/uiSlice';
import { sortBy } from '../../utils/sort';

const useClientDetails = () => {
  const { platformId } = useParams();
  const { pathname } = useLocation();
  const dispatch = useDispatch();

  const { clientDetailsItems, clientDetailsFilters, selectedClient, selectedPlatformId, clientDetailsSorting } =
    useSelector((state) => state.homeClientDetails);

  const { startDate, endDate } = clientDetailsFilters;

  const [quantitiesUpdated, setQuantitiesUpdated] = useState(false);

  const fetchItems = () => {
    dispatch(clearClientDetailsItems());
    dispatch(clearOverview());

    const apiSearch = pathname.startsWith('/dashboard/allClient') ? 'detailAll' : 'detail';

    homeApi[apiSearch].post({startDate, endDate, platformId})
      .then(async (response) => {
        let items = response.data.detail;

        let top5 = response.data.top5Opportunities.map(p => {
          const name = p.product.toLowerCase().split(" ");
          for (var i = 0; i < name.length; i++) {
            name[i] = name[i].charAt(0).toUpperCase() + name[i].slice(1);
          }
          return {
            ...p,
            product: name.join(' ')
          }
        });

        console.log(top5);

        dispatch(setOverview({
          volume: response.data.volume,
          rebateSavings: response.data.rebateSavings,
          deviationSavings: response.data.deviationSavings,
          topOpportunities: top5
        }));
        dispatch(setMyClientDetailsItems(items));
        dispatch(setSpinner(false));

        // Getting similar items quantities
        if (clientDetailsSorting.field) {
          items = sortBy(
            items,
            clientDetailsSorting.field,
            clientDetailsSorting.type
          );
        }
        let mins = items.map(
          (item) => ({
            min: item.min,
            manufacturerPlatformId: item.manufacturerPlatformId,
            manufacturerProductId: item.manufacturerProductId
          })
        );
        const chunkSize = 50;
        for (let i = 0; i < mins.length; i += chunkSize) {
          const chunk = mins.slice(i, i + chunkSize);
          items = await fetchSimilarItemsQuantities(i, items, chunk);
        }
      })
      .catch((error) => {
        console.log(error);
        dispatch(setClientDetailsItemsError());
        dispatch(setSpinner(false));
      });
  };

  const fetchSimilarItemsQuantities = async (i, items, mins) => {
    let newItems = [...items];

    await homeApi.similarItems.post({ startDate, endDate, platformId, mins })
      .then((response) => {
        let similarItems = response.data;
        items.forEach((item, index) => {
          const hasSimilarItems = similarItems.some(({ manufacturerProductId, savingItems }) => {
            if (item.manufacturerProductId == manufacturerProductId) {
              let newItem = {
                ...item,
                similarItems: savingItems,
              };
              newItems.splice(index, 1, newItem);
              return true;
            }
          });
          if (!hasSimilarItems) {
            mins.some(({ manufacturerProductId }) => {
              if (item.manufacturerProductId == manufacturerProductId && item.similarItems === undefined) {
                let newItem = { ...item, similarItems: 0 };
                newItems.splice(index, 1, newItem);
                return true;
              }
            });
          }
        });
        dispatch(setMyClientDetailsItems(newItems));
        setQuantitiesUpdated(true);
      })
      .catch((error) => {
        console.log(error);
        items.forEach((item, index) => {
          mins.some(({ manufacturerProductId }) => {
            if (item.manufacturerProductId == manufacturerProductId) {
              let newItem = { ...item, similarItems: -1 };
              newItems.splice(index, 1, newItem);
              return true;
            }
          });
        });
        dispatch(setMyClientDetailsItems(newItems));
        setQuantitiesUpdated(true);
      });

    return newItems;
  };

  const fetchAccountName = () => {
    generalApi
      .getAccount(platformId)
      .then((response) => {
        dispatch(setSelectedClient(response.data.name));
        dispatch(setSelectedPlatform(platformId));
      })
      .catch((error) => {
        console.log(error);
        dispatch(setSelectedClient('...'));
        dispatch(setSelectedPlatform(null));
      });
  };

  const searchItems = () => {
    dispatch(updateClientDetailsItems({ resetPage: true }));
  };

  useEffect(() => {
    if(platformId !== selectedPlatformId){
      console.log({clientDetailsItems})
      // if (!clientDetailsItems || clientDetailsItems.length === 0) {
      fetchAccountName();
      fetchItems();
      // }

      return () => {
        dispatch(resetClientDetailsState());
      };
    }

  }, []);

  useEffect(() => {
    if (quantitiesUpdated) {
      dispatch(updateClientDetailsItems());
    }
  }, [quantitiesUpdated]);

  return {
    // Data related
    platformId,
    clientDetailsFilters,
    selectedClient,
    fetchItems,
    // Other
    searchItems,
  };
};

export default useClientDetails;
