
import { Form, Col, Button } from 'react-bootstrap';
import Select from 'react-select';
import { selectStyles } from '../../config/constants';

const ClientSwitchForm = ({onLoginAccount, selectProps}) => {
  return (
    <Form onSubmit={onLoginAccount}>
      <Form.Group>
        <Form.Label htmlFor='user' className='fs-5 text-gray-text'>User *</Form.Label>
        <Select name='user' styles={selectStyles(false)} {...selectProps} />
      </Form.Group>
      <Form.Group>
        <Col className='py-3'>
          <Button type='submit' disabled={selectProps.value.length === 0} variant='secondary' className='shadow-none w-100'>Log In</Button>
        </Col>
      </Form.Group>
    </Form>
  )
}

export default ClientSwitchForm;