import './scss/App.scss';
import useAxios from './Components/useAxios/useAxios';
import Router from './Views/Router';
import { Container } from 'react-bootstrap';
import Spinner from './Components/Spinner/Spinner';

function App() {
  const { ready } = useAxios();

  if (!ready) {
    return null;
  }
  return (
    <Container fluid className='p-0 min-vh-100'>
      <Spinner />
      <Router />
    </Container>
  );
}

export default App;
