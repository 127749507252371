import { Container, Stack } from 'react-bootstrap';
import { FaChevronRight } from 'react-icons/fa';
import { Link } from 'react-router-dom';
import ItemDescription from './ItemDescription';
import SimilarItemsTable from './SimilarItemsTable';
import useSimilarItems from './useSimilarItems';

const SimilarItems = () => {
  let {
    platformId,
    selectedItem,
    selectedClient,
    processSorting,
    handleClickExportButton
  } = useSimilarItems();

  const similarItemsTableProps = {
    processSorting,
    handleClickExportButton,
    merchant: {
      nickname: selectedClient,
      clientPlatformId: platformId,
    }
  };

  return (
    <Container fluid className='px-5 py-3 main-container bg-gray'>
      <Stack direction='horizontal' gap={1}>
        <Link to='/dashboard' className='link fs-16 fw-bold'>
          Clients
        </Link>
        <FaChevronRight />
        <Link
          to={`/dashboard/client/${platformId}`}
          className='link fs-16 fw-bold'
        >
          {selectedClient}
        </Link>
        <FaChevronRight />
        <span className='fs-20 fw-bold text-capitalize'>
          {selectedItem ? selectedItem.product.toLowerCase() : '...'}
        </span>
      </Stack>
      <ItemDescription handleClickExportButton={handleClickExportButton} />
      <SimilarItemsTable {...similarItemsTableProps} />
    </Container>
  );
};

export default SimilarItems;
