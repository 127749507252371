import { Row, Col, Overlay, Popover } from 'react-bootstrap';

const TopBarOptions = (props) => {
  const { options, showOptions, targetOptions, refOptions } = props;
  
  return (
    <Row>
      <Col ref={refOptions}>
        <Overlay
          show={showOptions}
          target={targetOptions}
          placement="left"
          container={refOptions}
          containerPadding={20}
        >
          <Popover id="popover-contained">
            { options.map((o, i) =>
               <Popover.Body className='py-2' key={`opt_${i}`} onClick={o.onClick}>
                 {o.label}
              </Popover.Body>
            )}
          </Popover>
        </Overlay>
      </Col>
    </Row>
  );
};

export default TopBarOptions;
