import AuthRouter from "./AuthRouter";
import UnAuthRouter from "./UnAuthRouter";
import Header from '../Components/Header/Header';
import HeaderAuth from '../Components/Header/HeaderAuth';
// import { useSelector  } from 'react-redux';
import { instance } from '../sso.js'
import LocalStorage from '../Components/LocalStorage/LocalStorage';

const Router = () => {
  // const { isLogin } = useSelector(({user}) => user);
  const accounts = instance.getAllAccounts();
  const authKey = LocalStorage.authKey.get();
  if (accounts && authKey) {
    return (
      <>
        <HeaderAuth />
        <AuthRouter />
      </>
    )
  } 
  return (
    <>
      <Header />
      <UnAuthRouter />
    </>
  );
};

export default Router;

