import { Col, Container, Row } from 'react-bootstrap';
import RegisterForm from './RegisterForm';

const Register = () => {
  return (
    <Container fluid className='flex-grow-1'>
      <Row className='justify-content-center'>
        <Col style={{maxWidth: '400px'}}>
          <Row className='py-5'>
            <Col className='text-center fs-2'>Create Account</Col>
          </Row>
          <RegisterForm  />
        </Col>
      </Row>
    </Container>
  );
};

export default Register;
