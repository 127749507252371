import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import homeApi from '../../api/homeApi';
import { setClientDetailsFilters } from '../../Reducers/home/homeClientDetailsSlice';
import {
  setClientsFilters,
  setClientsSorting,
  resetMyClients,
  setMyClients,
  setActiveHomeTab,
  clearClients,
  updateClients
} from '../../Reducers/home/homeSlice';

const useHome = () => {
  const dispatch = useDispatch();
  const { pathname } = useLocation();
  const isListActive =
    pathname === '/dashboard' || pathname === '/dashboard/list';

  const {
    clients,
    clientsFilters,
    activeHomeTab
  } = useSelector((state) => state.home);

  /**
   * @desc Data for "My Clients" tab
   */
  const fetchClients = () => {
    dispatch(resetMyClients(true));
    const { startDate, endDate } = clientsFilters;
    
    homeApi.post(startDate, endDate).then((response) => {
      dispatch(setMyClients(response.data));
    }).catch((error) => {
      console.log(error);
      dispatch(resetMyClients());
    });
  };

  /**
   * @desc To change between  "My Clients" and "All Clients" tabs
   * @param {string} tabValue - My Clients || All Clients
   */
  const handleClickTab = (tabValue) => {
    if (tabValue !== activeHomeTab) {
      dispatch(setActiveHomeTab(tabValue));
    }
  };

  const fetchFilters = () => {
    homeApi.filters.post().then((response) => {
      dispatch(setClientsFilters({clients: response.data.client || []}));
    }).catch((error) => {
      console.log(error);
    });
  };

  const processSorting = ({ field, type }) => {
    dispatch(setClientsSorting({ field, type }));
  };

  const handleChangeStartDate = (date) => {
    dispatch(setClientsFilters({ startDate: date.toISOString() }));
    dispatch(setClientDetailsFilters({ startDate: date.toISOString() }));
  };

  const handleChangeEndDate = (date) => {
    dispatch(setClientsFilters({ endDate: date.toISOString() }));
    dispatch(setClientDetailsFilters({ endDate: date.toISOString() }));
  };

  useEffect(() => {
    if (activeHomeTab === 'myClients') {
      dispatch(updateClients());
    } else if (activeHomeTab === 'allClients') {
      dispatch(clearClients());
    }
    
    return () => {};
  }, [activeHomeTab]);
  
  useEffect(() => {
    dispatch(setActiveHomeTab('myClients'));
    fetchFilters();
    fetchClients();
  }, []);

  const loadMoreClients = () => {
    setTimeout(() => {
      // dispatch(fetchClients());
    }, 250);
  };

  return {
    // Data related
    clients,
    clientsFilters,
    fetchClients,
    infiniteClientsList: {
      dataLength: clients.length,
      next: loadMoreClients,
      hasMore: false,
      scrollThreshold: 1,
      loader: <div className='text-center p-2 border-top text-gray-text'>Loading ...</div>,
      endMessage: <div className='text-center p-2 border-top text-gray-text'>No more items</div>
    },
    // Other
    isListActive,
    activeHomeTab,
    handleClickTab,
    processSorting,
    handleChangeStartDate,
    handleChangeEndDate,
  };
};

export default useHome;
