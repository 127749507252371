import { useState, useEffect, useRef } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { AiFillHome } from 'react-icons/ai';
import { instance } from '../../sso.js';


const useTopBar = () => {
  const [showOptions, setShowOptions] = useState(false);
  const [targetOptions, setTargetOptions] = useState(null);
  const [linksMenu, setLinksMenu] = useState([]);
  const refOptions = useRef(null);
  const navigate = useNavigate();
  const { pathname }  = useLocation();

  useEffect(() => {
    getTopBarInit();
  }, []);

  const getTopBarInit = () => {
    setLinksMenu([{
      title: 'HOME',
      url: '/dashboard',
      icon: <AiFillHome color={'white'} size={18} />
    }])
  }

  const goToHome = () => {
    navigate('/dashboard');
  }
  const goToSwitchAccount = () => {
    navigate('/account');
  }
  const signOut = () => {
    const logoutRequest = {
      account: instance.getActiveAccount().homeAccountId
    }
    instance.logoutPopup(logoutRequest);
    localStorage.clear();
    sessionStorage.clear();
    navigate('/login');
    setTimeout(() => {
      window.location.reload();
    }, 500);
  }

  const onClickOptions = (event) => {
    setShowOptions(!showOptions);
    setTargetOptions(event.target);
  };

   const options = [
    { label: 'Logout',
      onClick: signOut
    },
    { label: 'Switch Account',
      onClick: goToSwitchAccount,
    }
  ];

  return {
    options,
    showOptions,
    targetOptions,
    refOptions,
    linksMenu,
    pathname,
    onClickOptions,
    goToHome
  }
}

export default useTopBar;
