import { Route, Routes, Navigate } from 'react-router-dom';
import ClientDetails from './HomeClientDetails/ClientDetails';
import SimilarItems from './HomeSimilarItems/SimilarItems';
import ClientSwitch from './ClientSwitch/ClientSwitch';
import Home from './Home/Home';
import HomeListView from './Home/HomeListView/HomeListView';
import HomeTableView from './Home/HomeTableView/HomeTableView';
import Redirect from './Redirect/Redirect';
import { useEffect } from 'react';
import userApi from '../api/userApi';
import { instance } from '../sso';
import { useDispatch } from 'react-redux';
import { setUserInfo } from '../Reducers/user/userSlice';

const AuthRouter = () => {
  const dispatch = useDispatch();
  useEffect(() => {
    userApi.get().then(({data}) => {
      const currentAccounts = instance.getAllAccounts();
      dispatch(setUserInfo(data));
      const acc = currentAccounts[0]?.username;
      if (acc) {
        // Fullstory Identify
        if(window['_fs_namespace']) {
          window[window['_fs_namespace']].identify(acc, {
            userEmail: acc,
            client : data.email,
            sfId : data.sfId
          });
        }
      }
    }).catch(err => {
      console.log(err);
    });
  }, []);
  return (
    <Routes>
      <Route path='/redirect' element={<Redirect />} />
      <Route path='/account' element={<ClientSwitch />} />
      <Route path='/dashboard'>
        <Route element={<Home />}>
          <Route index element={<HomeListView />} />
          <Route path='list' element={<HomeListView />} />
          <Route path='table' element={<HomeTableView />} />
        </Route>
        <Route path='client'>
          <Route path=':platformId' element={<ClientDetails />} />
          <Route
            path=':platformId/item/:itemPlatformId/:productId'
            element={<SimilarItems />}
          />
        </Route>
        <Route path='allclient'>
          <Route path=':platformId' element={<ClientDetails />} />
          <Route
            path=':platformId/item/:itemPlatformId/:productId'
            element={<SimilarItems />}
          />
        </Route>
      </Route>
      <Route path='*' element={<Navigate replace to='/dashboard' />} />
    </Routes>
  );
};

export default AuthRouter;
