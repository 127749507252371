import { Card, Col, Stack } from 'react-bootstrap';

const HomeOverviewBox = ({title, value, img, expand}) => {
	if (expand) {
		return(
			<Col xs={3}>
				<Card className='rounded-0 shadow-sm h-100'>
					<Card.Body className='d-flex flex-column justify-content-end'>
						<Card.Title className='fs-24 flex-grow-1'>{title}</Card.Title>
						<Stack direction='horizontal' gap={2}>
							<div className='pe-3'>
								<img src={img} alt={`${title}_icon`} />
							</div>
							<div className='lh-60 fs-40 fw-bold'>{value}</div>
						</Stack>
					</Card.Body>
				</Card>
			</Col>
		);
	}
	return(
		<Col xs={3}>
			<Card className='rounded-0 shadow-sm h-100'>
				<Card.Body className='d-flex flex-column justify-content-center'>
					<Card.Title className='fs-24 mb-0'>
						<Stack direction='horizontal'>
							<div className='col'>{title}</div>
							<div className='text-end'>{value}</div>
						</Stack>
					</Card.Title>
				</Card.Body>
			</Card>
		</Col>
	);
};

export default HomeOverviewBox;