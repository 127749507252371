import { createSlice } from '@reduxjs/toolkit';
import { sortBy } from '../../utils/sort';

const initialState = {
  selectedItem: null,
  similarItems: [],
  isLoading: true,
  isError: false,
  similarItemsSorting: {
    field: null,
    type: 'ASC',
  },
};

const updateItems = (state) => {
  state.isLoading = false;
  state.isError = false;

  const sorted = state.similarItems.length > 0 ? sortBy(
    state.similarItems,
    state.similarItemsSorting.field,
    state.similarItemsSorting.type,
  ) : [];

  state.similarItems = sorted;
};

const homeClientDetails = createSlice({
  name: 'homeSimilarItems',
  initialState,
  reducers: {
    setIsLoading: (state, action) => {
      state.isLoading = action.payload;
      state.isError = action.payload === true ? false : state.isError;
    },
    setError: (state) => {
      state.isLoading = false;
      state.isError = true;
    },
    setItems: (state, action) => {
      state.selectedItem = action.payload.item;
      state.similarItems = action.payload.matches
      updateItems(state);
    },
    clearItems: (state) => {
      state.selectedItem = null;
      state.similarItems = [];
      state.isLoading = true;
      state.isError = true;
    },
    setSimilarItemsSorting: (state, action) => {
      state.similarItemsSorting = {
        ...state.similarItemsSorting,
        ...action.payload,
      };
      updateItems(state);
    },
  },
});

export const {
  setIsLoading,
  setError,
  setItems,
  clearItems,
  setSimilarItemsSorting
} = homeClientDetails.actions;
export default homeClientDetails.reducer;
