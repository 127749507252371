import Axios from 'axios';

const authApi = {
  login: {
    post: (params) => {
      return Axios.post(`/login`, params);
    }
  }
};

export default authApi;