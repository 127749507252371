
import { Col, Container, Row } from 'react-bootstrap';
import ClientSwitchForm from './ClientSwitchForm';
import useClientSwitch from './useClientSwitch';

const ClientSwitch = () => {
  const { selectProps, onLoginAccount } = useClientSwitch();
  const propsClient = {
    selectProps,
    onLoginAccount
  }

  return (
    <Container fluid>
      <Row className='justify-content-center'>
        <Col style={{maxWidth: '400px'}}>
          <Row className='py-5'>
            <Col className='text-center fs-2'>Select Account</Col>
          </Row>
          <ClientSwitchForm {...propsClient}  />
        </Col>
      </Row>
    </Container>
  )
}

export default ClientSwitch;
