import { useDispatch, useSelector } from 'react-redux';
import homeApi from "../../../api/homeApi";
import { addMyClientToSearch, removeMyClientToSearch, clearMyClientToSearch, selectAllClientsSearchItem, setAllClients, setError } from '../../../Reducers/home/homeSlice';
import { Badge, CloseButton, Col } from 'react-bootstrap';
import { selectStyles } from "../../../config/constants";

const useHomeFiltersSearch = () => {
	const dispatch = useDispatch();

	const {
		activeHomeTab,
		clientsFilters,
		myClientsSearch
	} = useSelector(state => state.home);

	const remove = (client) => {
		dispatch(removeMyClientToSearch(client));
	};

	const myClientsSelected = myClientsSearch.map(c => (
		<Col className='mw-fc' key={`myclientbadge_${c.label}`}>
			<Badge className='fw-bold fs-12' bg='tags'>
				{c.label}
				<CloseButton className='ps-3 fs-8 opacity-100' onClick={() => remove(c)} variant='white' />
			</Badge>
		</Col>
	));

	const clearMyClientsSearch = () => {
		dispatch(clearMyClientToSearch());
	};

	return {
		activeHomeTab,
		myClientsSelected,
		clearMyClientsSearch,
		myClientsSearch: {
			options: clientsFilters.clients.map(c => ({
				label: c.name,
				value: c
			})).filter(client => (
				!myClientsSearch.map(o => o.label).includes(client.label)
			)),
			onChange: (selectedClient) => {
				dispatch(addMyClientToSearch(selectedClient));
			},
			placeholder: 'Search by client name...',
			styles: selectStyles(true),
			className: 'w-100'
		},
		allClientsSearch: {
			loadOptions: (searchValue) => {
				if (!searchValue) {
					return new Promise((resolve) => {
						setTimeout(() => {
							resolve([]);
						} , 250);
					});
				}

				return new Promise((resolve, reject) => {
					homeApi.merchants.get({search: searchValue}).then(response => {
						const options = response.data.map(({ client, platformId }) => {
							return { label: client, value: platformId };
						});
						resolve(options);
					}).catch((error) => {
						reject(error);
					});
				});
			},
			onChange: (elem) => {
				dispatch(selectAllClientsSearchItem(elem));
				const { startDate, endDate } = clientsFilters;
				homeApi.platformId(elem.value).get({startDate, endDate}).then((response) => {
					dispatch(setAllClients(response.data.clients));
				}).catch((error) => {
					console.log(error);
					dispatch(setError());
				});
			},
			placeholder: 'Search by client name...',
			styles: selectStyles(true),
			className: 'w-100'
		}
	};
};

export default useHomeFiltersSearch;