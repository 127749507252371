
//react-select styles
export const selectStyles = (hasIcon) => ({
  control: (provided, state) => {
    return {
      ...provided,
      padding: 0,
      borderRadius: 'none',
      minHeight: '33.5px',
      borderColor: state.isFocused ? '#D7D8D9' : '#ced4da',
      ':hover': {
        borderColor: '#D7D8D9'
      },
      boxShadow: 'none'
    };
  },
  menu: (provided) => {
    return {
      ...provided,
      zIndex: 2,
      borderRadius: 'none',
    };
  },
  valueContainer: (provided) => {
    return {
      ...provided,
      padding: '0.375rem 2.25rem 0.375rem 0.75rem',
      paddingLeft: hasIcon ? '1.8rem' : '0.75rem',
      fontSize: '1rem',
      transform: 'translateY(2px)'
    };
  },
  input: (provided) => {
    return {
      ...provided,
      margin: 0,
      padding: 0,
    };
  },
  placeholder: (provided) => {
    return {
      ...provided,
      color: '#76777B',
      padding: 0,
      fontSize: '1rem'
    };
  },
  indicatorSeparator: (provided) => {
    return {
      ...provided,
      display: 'none'
    };
  },
  dropdownIndicator: (provided, state) => {
    return {
      ...provided,
      padding: '0 8px',
      color: state.isFocused ? '#D7D8D9' : '#76777B',
      ':hover': {
        color: '#D7D8D9'
      }
    };
  },
});