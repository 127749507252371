import { memo } from 'react';
import { Col, Spinner, Stack } from 'react-bootstrap';
import { Link } from 'react-router-dom';

const ClientDetailsTableRow = ({ item }) => {
  const {
    min,
    product,
    manufacturer,
    brand,
    exclusions,
    packsize,
    quantity,
    uom,
    purchase,
    gpoContract,
    platformId,
    productId,
    similarItems,
  } = item;

  const getItemsDisplay = (similarItemsQuantity) => {
    if (similarItemsQuantity === null || similarItemsQuantity === undefined)
      return (
        <>
          <Spinner animation='border' size='sm' /> View items
        </>
      );
    if (similarItemsQuantity >= 0) return <>{similarItemsQuantity} items</>;
    return 'View items';
  };

  return (
    <Stack direction='horizontal' className='hoverable border-top p-2'>
      <Col className='text-truncate'>{min}</Col>
      <Col className='text-capitalize text-truncate'>
        {product.toLowerCase()}
      </Col>
      <Col xs={1} className='text-capitalize text-truncate'>{manufacturer}</Col>
      <Col xs={1} className='text-capitalize text-truncate'>{brand}</Col>
      <Col xs={1}>
        <Stack direction='horizontal' gap={1}>
          <Stack
            direction='horizontal'>
            &nbsp;&nbsp;
          </Stack>          
          <Stack
            direction='horizontal' >
            {exclusions ? 'Yes' : 'No'}
          </Stack>
        </Stack>
      </Col>
      <Col xs={1} className='text-end'>
        <Stack direction='horizontal'>
          <Stack
            direction='horizontal' className='ms-auto'>
            {packsize}
          </Stack>          
          <Stack
            direction='horizontal'>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          </Stack>
        </Stack>
      </Col>
      <Col xs={1}>
        <Stack direction='horizontal'>
          <Stack
            direction='horizontal' className='ms-auto'>
            {quantity && quantity.toLocaleString('en-US', {minimumFractionDigits: 2, maximumFractionDigits: 2})}
          </Stack>          
          <Stack
            direction='horizontal'>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          </Stack>
        </Stack>
      </Col>
      <Col xs={1} className='text-capitalize'>
        <Stack direction='horizontal' gap={1}>
          <Stack
            direction='horizontal'>
            &nbsp;&nbsp;
          </Stack>          
          <Stack
            direction='horizontal' >
            {uom.toLowerCase()}
          </Stack>
        </Stack>
      </Col>
      <Col xs={1}>
        <Stack direction='horizontal'>
          <Stack
            direction='horizontal' className='ms-auto'>
            {purchase &&
              quantity && 
              '$' + (purchase / quantity).toLocaleString('en-US', {minimumFractionDigits: 2, maximumFractionDigits: 2})}
          </Stack>          
          <Stack
            direction='horizontal'>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          </Stack>
        </Stack>
      </Col>
      <Col xs={1}>
        <Stack direction='horizontal'>
          <Stack
            direction='horizontal' className='ms-auto'>
            {purchase && '$' + purchase.toLocaleString('en-US', {minimumFractionDigits: 2, maximumFractionDigits: 2})}
          </Stack>          
          <Stack
            direction='horizontal'>
            &nbsp;&nbsp;&nbsp;
          </Stack>
        </Stack>        
      </Col>
      <Col xs={1}>
        <Stack direction='horizontal' gap={1}>
          <Stack
            direction='horizontal'>
            &nbsp;&nbsp;
          </Stack>          
          <Stack
            direction='horizontal' >
            {gpoContract ? 'Yes' : 'No'}
          </Stack>
        </Stack>
      </Col>
      <Col xs={1}>
        <Link to={`item/${platformId}/${productId}`} className='link p-2'>
          {getItemsDisplay(similarItems)}
        </Link>
      </Col>
    </Stack>
  );
};

export default memo(ClientDetailsTableRow);
