import { Card, Col, Row, Spinner } from 'react-bootstrap';
import HighchartsReact  from 'highcharts-react-official';
import Highcharts from 'highcharts';
import { useState } from 'react';
import { FaChevronDown } from 'react-icons/fa';
import { useSelector } from 'react-redux';

const HomeOverviewGraph = ({title, descriptionXS, headers, data}) => {
	const [page, setPage] = useState(1);

	const isLoading = useSelector(state => state.home.isLoading);

	const config = {
		chart: {
			height: 200,
			width: 179,
			plotBackgroundColor: null,
			plotBorderWidth: null,
			plotShadow: false,
			type: 'pie',
			margin: 0
		},
		title: {
			text: ''
		},
		accessibility: {
			enabled: false
		},
		tooltip: {
      backgroundColor: '#1A1A1A',
      borderColor: '#1A1A1A',
      borderRadius: 5,
      valueDecimals: 2,
      style: {
        color: 'white'
      },
      formatter: function () {
        return this.key+' - $'+this.y.toLocaleString('en', {minimumFractionDigits: 2, maximumFractionDigits: 2});
      }
    },
		credits: {
			enabled: false
		},
		series: [{
			data: data.filter((d, i) => i < page * 5)
		}],
		plotOptions: {
			pie: {
				clip: false,
				center: ['50%', 90],
				dataLabels: {
					enabled: false
				}
			}
		}
	};

	const rowMap = data.map((d, i) => (
		<Row key={`data_${title}_${i}`} className='border-bottom hoverable py-2 gx-1'>
			<Col xs={descriptionXS} className='fw-bold text-truncate'>{d.displayName || d.name}</Col>
			<Col className='text-gray-text text-truncate'>{d.data[0]}</Col>
			<Col className='text-gray-text text-truncate'>{d.data[1]}</Col>
		</Row>
	));

	if (isLoading) {
		return(
			<Col xs={6}>
				<Card className='rounded-0 shadow-sm h-100'>
					<Card.Body className='d-flex flex-column'>
						<Card.Title className='fs-24'>{title}</Card.Title>
						<Row className='flex-grow-1'>
							<Col className='text-center'>
								<Spinner animation='border' />
							</Col>
						</Row>
					</Card.Body>
				</Card>
			</Col>
		);
	}

	return(
		<Col xs={6}>
			<Card className='rounded-0 shadow-sm h-100'>
				<Card.Body className='d-flex flex-column'>
					<Card.Title className='fs-24'>{title}</Card.Title>
					<Row className='flex-grow-1'>
						<Col className='col-200px'>
							<HighchartsReact highcharts={Highcharts} options={config}></HighchartsReact>
						</Col>
						<Col className='d-flex flex-column'>
							<Row className='fw-bold border-bottom hoverable pb-2 gx-1'>
								<Col xs={{offset: descriptionXS}}>{headers[0]}</Col>
								<Col>{headers[1]}</Col>
							</Row>
							{rowMap.filter((d, i) => i < page * 5)}
							{
								(page) * 5 < data.length &&
								<Row>
									<Col onClick={() => setPage(page +1)} className='text-link clickable py-3 mw-fc'>
										Show More <FaChevronDown />
									</Col>
								</Row>
							}
						</Col>
					</Row>
				</Card.Body>
			</Card>
		</Col>
	);
};

export default HomeOverviewGraph;