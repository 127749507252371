import Joi from 'joi';
import useForm from '../../Components/useForm/useForm';
import { signIn } from '../../sso.js';

const initialState = {
  email: '',
  password: ''
};

const reducer = (state, action) => {
  return {
    ...state,
    [action.field]: action.value
  };
};

const schema = Joi.object({
  email: Joi.string().email({ tlds: { allow: false } }).label('Email').required(),
  password: Joi.string().min(3).label('Password').required()
});

const useLogin = () => {
  const { form, validate } = useForm(reducer, initialState, schema);
  // const navigate = useNavigate();
  // const dispatch = useDispatch();

  const onLogin = (e) => {
    e.preventDefault();
    signIn('redirect')
  };

  return {
    ...form,
    validate,
    onLogin
  };
}

export default useLogin;
