import { Button, Col, FormCheck, Row, Stack } from 'react-bootstrap';
import DatePicker from 'react-datepicker';
import { FaCalendar, FaChevronDown } from 'react-icons/fa';
import homeApi from '../../../api/homeApi';
import ClientDetailsFiltersSearch from './ClientDetailsFiltersSearch';
import useClientDetailsFilters from './useClientDetailsFilters';

const ClientDetailsFilters = (props) => {
  const {
    selectedClient,
    fetchItems,
    searchItems,
    clientDetailsFilters,
    platformId
  } = props;

  const { search, startDate, endDate, isNonCompliant } = clientDetailsFilters;

  const { handleClickStartDate, handleClickEndDate, handleClickNonCompliant, handleApiCall } =
    useClientDetailsFilters();

  const handleNonCompliant = (val) => {
      handleApiCall(false);
      handleClickNonCompliant(val)
      searchItems();
    };

  const handleClickApplyButton = () => {
    handleApiCall(true);
    fetchItems();
  };

  const handleClickExportButton = () => {
    homeApi.detail.excel.post({startDate, endDate, platformId}).then(res => {
      window.open(res.data.location, '_blank');
    });
  }

  return (
    <>
      <Row className='my-3 mx-0 align-items-start gx-2'>
        <Col className='fs-16 fw-bold mx-1 my-2'>
          {selectedClient} Products
        </Col>
      </Row>
      <Row className='my-3 mx-0 align-items-start gx-2'>
        <Col className='fw-bold' md={2} lg={3} xl={4}>
          <ClientDetailsFiltersSearch
            search={search}
            searchItems={searchItems}
          />
        </Col>
        <Col>
          <Stack direction='horizontal' gap={3}>
            <Stack
              direction='horizontal'
              className='border home-daterange-picker px-2'
            >
              <Stack direction='horizontal' className='me-1'>
                <FaCalendar color='var(--bs-gray-light)' />
              </Stack>
              <DatePicker
                locale='en'
                selected={new Date(startDate)}
                onChange={(date) => handleClickStartDate(date)}
              />
              -
              <DatePicker
                locale='en'
                selected={new Date(endDate)}
                onChange={(date) => handleClickEndDate(date)}
                minDate={new Date(startDate)}
              />
            </Stack>
            <FormCheck
              id='non-compliant-switch'
              className='mx-1'
              type='switch'
              label='Non-Compliant'
              role='button'
              defaultChecked = {false}
              value={isNonCompliant}
              onChange={(e) => { handleNonCompliant(e.target.checked) }}
            />
            <Button variant='secondary' onClick={handleClickApplyButton}>
              APPLY
            </Button>
          </Stack>
        </Col>
        <Col className='d-flex justify-content-end align-items-end'>
          <Button variant='primary' style={{width: '83px'}} onClick={handleClickExportButton}>
            Export <FaChevronDown />
          </Button>
        </Col>
      </Row>
    </>
  );
};

export default ClientDetailsFilters;
