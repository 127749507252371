import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Form, Button } from "react-bootstrap";
import { useNavigate } from 'react-router-dom';

const LoginForm = ({ onLogin }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    (async () => {
    })()
  }, [dispatch, navigate]);

  return (
    <Form onSubmit={(e) => onLogin(e)}>
      <Form.Group className='pt-4'>
        <Button
          type='submit'
          variant='secondary'
          className='shadow-none w-100'
        >
          Microsoft
        </Button>
      </Form.Group>
    </Form>
  );
};

export default LoginForm;
