import Axios from 'axios';

// eslint-disable-next-line no-undef
const url = `${process.env.REACT_APP_API_URL}/user`;

const userApi = {
  get: () => {
    return Axios.get(`${url}`);
  }
};

export default userApi;
