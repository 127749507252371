import { memo } from 'react';
import { Row } from 'react-bootstrap';
import HomeListClientRow from './HomeListClientRow';
import { useSelector } from 'react-redux';
import InfiniteScroll from 'react-infinite-scroll-component';
import { useOutletContext } from 'react-router-dom';

const HomeListView = () => {
  const { infiniteClientsList } = useOutletContext();
  const {
    isLoading, isError, clients,
    isLoadingAllClients, activeHomeTab
  } = useSelector(state => state.home);

  const getStatusMessage = () => {
    if (isLoading || isLoadingAllClients) return 'Loading ...';
    if (isError)
      return (
        <>
          Something went wrong, please
          <br />
          <span
            role='button'
            className='link'
            onClick={() => window.location.reload()}
          >
            reload the page
          </span>
        </>
      );
    if (clients.length === 0)
      return 'We did not find any results, please try with different filters.';
  };

  return (
    <Row className='mx-0 p-0 gx-0'>
      {(!isLoading && !isError && clients.length > 0) ? (
        <InfiniteScroll {...infiniteClientsList}>
          {clients.map((item) => (
            <HomeListClientRow {...item} key={`list_${item.client}`} activeHomeTab={activeHomeTab} />
          ))}
        </InfiniteScroll>
      ) : (
        <div className='py-3 lh-sm text-center'>{getStatusMessage()}</div>
      )}
    </Row>
  );
};

export default memo(HomeListView);
