import { Col, Stack } from 'react-bootstrap';
import { FaChevronRight } from 'react-icons/fa';
import { Link } from 'react-router-dom';

const ClientDetailsOverviewBox = ({
  type = 'box',
  className,
  title,
  value,
  icon,
  isOverviewCollapsed
}) => {
  if (type === 'box')
    return (
      <Col xs={3} className={className}>
        <Stack className='p-3 bg-white border border-gray-lighter shadow-box rounded h-100'>
          {isOverviewCollapsed ? (
            <Stack direction='horizontal'>
              <div className='fs-24' style={{ lineHeight: 1 }}>
                {title}
              </div>
              <div className='ms-auto fs-20 fw-bold'>{value}</div>
            </Stack>
          ) : (
            <>
              <div className='fs-24 mb-2' style={{ lineHeight: 1 }}>
                {title}
              </div>
              <div className='mt-auto'>
                {icon}
                <br />
                <span className='mt-2 fs-40'>{value}</span>
              </div>
            </>
          )}
        </Stack>
      </Col>
    );
  else if (type === 'list')
    return (
      <Col xs={3} className={className}>
        <div className='pt-3 pb-1 bg-white border border-gray-lighter shadow-box rounded h-100'>
          <div className='mb-3 mx-3 fs-24 text-capitalize' style={{ lineHeight: 1 }}>
            {title}
          </div>
          {!isOverviewCollapsed &&
            Array.isArray(value) &&
            value.map((item, index) => (
              <Stack
                key={index}
                direction='horizontal'
                className='px-3 p-1 border-top hoverable'
              >
                <span className='text-truncate'>{item.name}</span>
                <Link
                  to={item.link}
                  className='ms-auto'
                >
                  <FaChevronRight color='var(--bs-dark)' />
                </Link>
              </Stack>
            ))}
        </div>
      </Col>
    );
};

export default ClientDetailsOverviewBox;
