const authKey = {
	get: () => {
		const authKey = localStorage.getItem('authKey');
		return authKey ? authKey : null;
	},
	set: (value) => {
		localStorage.setItem('authKey', value);
	},
};

const resetKey = {
	get: () => {
		const resetKey = localStorage.getItem('resetKey');
		return resetKey ? resetKey : null;
	},
	set: (value) => {
		localStorage.setItem('resetKey', value);
	},
};

const userData = {
	get: () => {
		const userData = localStorage.getItem('userData');
		return userData ? JSON.stringify(userData) : null;
	},
	set: (value) => {
		localStorage.setItem('userData', JSON.stringify(value));
	},
};

const isAdmin = {
	get: () => {
		const isAdmin = localStorage.getItem('isAdmin');
		return isAdmin ? JSON.stringify(isAdmin) : null;
	},
	set: (value) => {
		localStorage.setItem('isAdmin', JSON.stringify(value));
	},
};

const clear = () => {
	localStorage.removeItem('authKey');
	localStorage.removeItem('resetKey');
	localStorage.removeItem('userData');
	localStorage.removeItem('isAdmin');
};

const LocalStorage = {
	authKey,
	resetKey,
	userData,
	clear,
    isAdmin
};

export default LocalStorage;
