import { Col, Container, Row } from "react-bootstrap";
import LoginForm from "./LoginForm";
import useLogin from './useLogin';

const Login = () => {
  const { onLogin } = useLogin();
  const loginProps = { onLogin };
  return (
    <Container fluid className='flex-grow-1'>
      <Row className='justify-content-center'>
        <Col style={{ maxWidth: "400px" }}>
          <Row className='py-5'>
            <Col className='text-center fs-2'>Login</Col>
          </Row>
          <LoginForm {...loginProps} />
        </Col>
      </Row>
    </Container>
  );
};

export default Login;
