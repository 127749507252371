import { createSlice } from '@reduxjs/toolkit';
import { endOfMonth, format, startOfMonth, subMonths } from 'date-fns';
import { sortBy } from '../../utils/sort';

const previousMonth = subMonths(new Date(), 1);
const initialState = {
  selectedClient: null,
  selectedPlatformId: null,
  myClientDetailsItems: null,
  clientDetailsItems: null,
  clientDetailsFilters: {
    search: null,
    startDate: startOfMonth(previousMonth).toISOString(),
    endDate: endOfMonth(previousMonth).toISOString(),
    oppotunities: null,
    isNonCompliant: false,
    apiCall: true
  },
  clientDetailsSorting: {
    field: 'quantity',
    type: 'DESC',
  },
  pagination: {
    page: 1,
    itemsPerPage: 50,
    totalItems: 0,
  },
  overview: {
    startDate: format(startOfMonth(previousMonth), 'MM/dd/yyyy'),
    endDate: format(endOfMonth(previousMonth), 'MM/dd/yyyy'),
    isOverviewCollapsed: false,
    volume: 0,
    rebateSavings: 0,
    deviationSavings: 0,
    topOpportunities: [],
  },
};

const updateClientDetailsItemsData = (state) => {
  const {
    myClientDetailsItems,
    clientDetailsSorting,
    clientDetailsFilters,
    pagination,
  } = state;

  if (myClientDetailsItems && myClientDetailsItems.length > 0) {
    let newItems = myClientDetailsItems;

    if (clientDetailsSorting.field) {
      newItems = sortBy(
        myClientDetailsItems,
        clientDetailsSorting.field,
        clientDetailsSorting.type
      );
    }
    if (clientDetailsFilters.search && clientDetailsFilters.search !== '') {
      newItems = newItems.filter((item) =>
        item.product.toLowerCase().includes(clientDetailsFilters.search)
      );
    }

    if (!clientDetailsFilters.apiCall) {
      newItems = newItems.filter(item => item.gpoContract == clientDetailsFilters.isNonCompliant);
    }

    state.pagination = { ...state.pagination, totalItems: newItems.length };
    newItems = newItems.slice(0, pagination.itemsPerPage * pagination.page - 1);

    state.clientDetailsItems = newItems;
  }
};

const homeClientDetails = createSlice({
  name: 'homeClientDetails',
  initialState,
  reducers: {
    setSelectedClient: (state, action) => {
      state.selectedClient = action.payload;
    },
    setSelectedPlatform: (state, action) => {
      state.selectedPlatformId = action.payload;
    },
    setClientDetailsItems: (state, action) => {
      state.clientDetailsItems = action.payload;
    },
    setMyClientDetailsItems: (state, action) => {
      state.myClientDetailsItems = action.payload;
      state.pagination = { ...state.pagination, page: 1 };
      updateClientDetailsItemsData(state);
    },
    updateClientDetailsItems: (state, action) => {
      if (action.payload && action.payload.resetPage)
        state.pagination = { ...state.pagination, page: 1 };
      updateClientDetailsItemsData(state);
    },
    loadMoreClientDetailsItems: (state) => {
      state.pagination = {
        ...state.pagination,
        page: state.pagination.page + 1,
      };
      updateClientDetailsItemsData(state);
    },
    clearClientDetailsItems: (state) => {
      state.myClientDetailsItems = null;
      state.clientDetailsItems = null;
    },
    setClientDetailsItemsError: (state) => {
      state.clientDetailsItems = false;
    },
    setClientDetailsFilters: (state, action) => {
      state.clientDetailsFilters = {
        ...state.clientDetailsFilters,
        ...action.payload,
      };
    },
    setClientDetailsSorting: (state, action) => {
      state.clientDetailsSorting = {
        ...state.clientDetailsSorting,
        ...action.payload,
      };
      updateClientDetailsItemsData(state);
    },
    setOverview: (state, action) => {
      state.overview = { ...state.overview, ...action.payload };
    },
    clearOverview: (state) => {
      // state.overview.startDate = startOfMonth(previousMonth).toISOString();
      // state.overview.endDate = endOfMonth(previousMonth).toISOString();
      state.overview.isOverviewCollapsed = false;
      state.overview.volume = 0;
      state.overview.rebateSavings = 0;
      state.overview.deviationSavings = 0;
      state.overview.topOpportunities = [];
    },
    resetClientDetailsState: (state) => {
      // eslint-disable-next-line no-unused-vars
      state = initialState;
    },
  },
});

export const {
  setSelectedClient,
  setSelectedPlatform,
  setMyClientDetailsItems,
  setClientDetailsItems,
  updateClientDetailsItems,
  loadMoreClientDetailsItems,
  clearClientDetailsItems,
  setClientDetailsItemsError,
  setClientDetailsFilters,
  setClientDetailsSorting,
  setOverview,
  clearOverview,
  resetClientDetailsState,
  totalItems,
} = homeClientDetails.actions;
export default homeClientDetails.reducer;
