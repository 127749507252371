import { LogLevel } from '@azure/msal-browser';

export const msalConfig = {
    auth: {
        clientId: process.env.REACT_APP_AZURE_APP_ID,
        authority: "https://login.microsoftonline.com/5c78d246-8728-42ae-963f-3907f14cadcd",
        redirectUri: process.env.REACT_APP_AZURE_REDIRECT_URI,
    },
    cache: {
        cacheLocation: "localStorage", // This configures where your cache will be stored
        storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge
    },
    system: {
        loggerOptions: {
            loggerCallback: (level, message, containsPii) => {
                if (containsPii) {
                    return;
                }
                switch (level) {
                    case LogLevel.Error:
                        if (process.env.REACT_APP_ENVIRONMENT === 'development') {
                            console.error(message);
                        }
                        return;
                    case LogLevel.Info:
                        if (process.env.REACT_APP_ENVIRONMENT === 'development') {
                            console.info(message);
                        }
                        return;
                    case LogLevel.Verbose:
                        if (process.env.REACT_APP_ENVIRONMENT === 'development') {
                            console.debug(message);
                        }
                        return;
                    case LogLevel.Warning:
                        if (process.env.REACT_APP_ENVIRONMENT === 'development') {
                            console.warn(message);
                        }
                        return;
                    default:
                        return;
                }
            },
        },
    },
};
