import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import generalApi from '../../api/generalApi';
import homeApi from '../../api/homeApi';
import { setSelectedClient } from '../../Reducers/home/homeClientDetailsSlice';
import { clearItems, setError, setItems, setSimilarItemsSorting } from '../../Reducers/home/homeSimilarItemsSlice';

const useSimilarItems = () => {
  const dispatch = useDispatch();
  let { platformId, itemPlatformId, productId } = useParams();

  const { clientDetailsFilters, selectedClient } = useSelector(
    (state) => state.homeClientDetails
  );

  const { selectedItem } = useSelector(state => state.homeSimilarItems);

  const { startDate, endDate } = clientDetailsFilters;

  const fetchItemMatches = () => {
    dispatch(clearItems());

    homeApi.itemMatch.post({ startDate, endDate, platformId: itemPlatformId, productId }).then((response) => {
      dispatch(setItems(response.data));
    }).catch((error) => {
      console.log(error);
      if (error.response.status === 402) {
        dispatch(setItems({ item: null, matches: [] }));
      } else {
        dispatch(setError());
      }
    });
    fetchAccountName();
  };

  const fetchAccountName = () => {
    generalApi
      .getAccount(platformId)
      .then((response) => {
        dispatch(setSelectedClient(response.data.name));
      })
      .catch((error) => {
        console.log(error);
        dispatch(setSelectedClient('...'));
      });
  };

  const processSorting = ({ field, type }) => {
    dispatch(setSimilarItemsSorting({ field, type }));
  };

  useEffect(() => {
    fetchItemMatches();
  }, []);

  const handleClickExportButton = () => {
    homeApi.itemMatch.excel.post({ startDate, endDate, platformId: itemPlatformId, productId }).then(res => {
      window.open(res.data.location, '_blank');
    });
  };

  return {
    // Params
    platformId,
    itemPlatformId,
    productId,
    // Data related
    clientDetailsFilters,
    selectedItem,
    selectedClient,
    // Other
    processSorting,
    handleClickExportButton
  };
};

export default useSimilarItems;
