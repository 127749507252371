import { Col, Container, Row } from 'react-bootstrap';

const ChangePassword = () => {
  return (
    <Container>
      <Row>
        <Col>Change Password View</Col>
      </Row>
    </Container>
  );
};

export default ChangePassword;
