import { Stack } from "react-bootstrap";
import { FaChevronRight } from "react-icons/fa";
import { Link } from "react-router-dom";

const ClientDetailsBreadcrumbs = ({ selectedClient }) => {
  return (
    <Stack direction='horizontal' gap={1}>
      <Link to='/dashboard' className='link fs-16 fw-bold'>
        Home
      </Link>
      <FaChevronRight />
      <span className='fs-20 fw-bold'>{selectedClient}</span>
    </Stack>
  );
};

export default ClientDetailsBreadcrumbs;
