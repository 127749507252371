import { Col, Container, Row } from 'react-bootstrap';

const ForgotPassword = () => {
  return (
    <Container>
      <Row>
        <Col>Forgot Password View</Col>
      </Row>
    </Container>
  );
};

export default ForgotPassword;
