import { Col, Stack } from 'react-bootstrap';
import { Link } from 'react-router-dom';

const HomeTableClientRow = ({activeHomeTab, ...props}) => {
  return (
    <Stack direction='horizontal' className='hoverable border-top p-2'>
      <Col xs={2}>{props.client}</Col>
      <Col xs={1}>{props.platformId}</Col>
      <Col xs={2} className='ps-3'>
        ${props.compliantVolume?.toLocaleString('en-US')}
      </Col>
      <Col xs={2} className='ps-3'>
        ${props.nonCompliantVolume?.toLocaleString('en-US')}
      </Col>
      <Col xs={2} className='ps-3'>
        {props.compliantVolumePercentage}%
      </Col>
      <Col xs={1} className='ps-3'>
        {props.numberOfNonCompliantMins}
      </Col>
      <Col xs={1} className='ps-3'>
        {props.subscriptionLevel}
      </Col>
      <Col xs={1}>
        <Link
          to={`/dashboard/${activeHomeTab === 'allClients' ? 'allClient' : 'client'}/${props.platformId}`}
          className='link p-2'
        >
          View Details
        </Link>
      </Col>
    </Stack>
  );
};

export default HomeTableClientRow;