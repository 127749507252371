import { createSlice } from '@reduxjs/toolkit';
import LocalStorage from '../../Components/LocalStorage/LocalStorage';
const isLogin = LocalStorage.authKey.get() ? true : false;
const isAdmin = LocalStorage.isAdmin.get() ? true : false;

const initialState = {
  isLogin,
  authKey: null,
  isAdmin,
  info: null
};

export const userSlice = createSlice({  
  name: 'user',
  initialState,
  reducers: {
    setUserData: (state, action) => {
      state.isLogin = action.payload.token ? true : false;
      state.isAdmin = action.payload.isAdmin ? true : false;
    },
    setUserInfo: (state, action) => {
      state.info = action.payload;
    }
  }
});

export const {
  setUserData,
  setUserInfo
} = userSlice.actions;

export default userSlice.reducer;
