import Axios from 'axios';

// eslint-disable-next-line no-undef
const url = `${process.env.REACT_APP_API_URL}/client-manager`;

const generalApi = {
  getAccount: (platformId) => {
    return Axios.get(`${url}/account/${platformId}`);
  },
};

export default generalApi;
