import { useSelector } from 'react-redux';

const Spinner = () => {
  const { spinner } = useSelector((state) => state.ui);
  
  return spinner ? (
    <div className='mask'>
      <div className='spinner'>
        <div className='bounce1'></div>
        <div className='bounce2'></div>
        <div className='bounce3'></div>
      </div>
    </div>
  ) : (
    <></>
  );
};

export default Spinner;
