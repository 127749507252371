import {  Container } from 'react-bootstrap';
import TopBar from '../TopBar/TopBar';

const HeaderAuth = () => {
  return (
    <Container fluid className='px-5 h-100 bg-primary' style={{height: '60px'}}>
      <TopBar />
    </Container>
  )
}

export default HeaderAuth;
