import config from "../config";

const randomColor = (i) => {
	const color = config.colorPalette;
	if (i > color.length) {
		let letters = '0123456789ABCDEF'.split('');
		let colorNew = '#';
		for (let j = 0; j < 6; j++) {
				colorNew += letters[Math.floor(Math.random() * 16)];
		}
		return colorNew;
	}
	return color[i];
};

export default randomColor;