import { Row, Col } from 'react-bootstrap';
import { FaUserAlt } from 'react-icons/fa';
import TopBarOptions from './TopBarOptions';
import useTopBar from './useTopBar';

const TopBar = () => {
  const {
    options,
    showOptions,
    targetOptions,
    refOptions,
    linksMenu,
    pathname,
    onClickOptions,
    goToHome,
  } = useTopBar();

  const optionsProps = {
    options,
    showOptions,
    targetOptions,
    refOptions,
    onClickOptions,
  };

  const linksMap = linksMenu.map((l, i) => (
		<Col key={`links_${i}`}
      onClick={goToHome}
			className={`${pathname.includes(l.url) ? 'active-topbar' : ''} d-flex justify-content-start align-items-center text-white fs-16 fw-bold`}
			xs='auto'
			role='button'
    >
			{l.icon} &nbsp;{l.title}
		</Col>
	));

  return (
    <Row>
      <Col className='mw-fc'>
        <img
          src='https://rebate-portal.s3.amazonaws.com/lops/be-white.png'
          alt='BE Logo'
          className=''
          style={{ maxWidth: '190px', maxHeight: '65px' }}
        />
      </Col>
      {linksMap}
      <Col className='ms-auto' />
      <Col
        className='d-flex justify-content-end align-items-center mw-fc'
        onClick={onClickOptions}
        role='button'
      >
        <TopBarOptions {...optionsProps} />
        <FaUserAlt color={'white'} size={18} />
      </Col>
    </Row>
  );
};

export default TopBar;
