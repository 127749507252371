import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  spinner: false
};

const uiSlice = createSlice({
  name: 'ui',
  initialState,
  reducers: {
    setSpinner: (state, action) => {
      state.spinner = action.payload;
    },
  },
});

export const { setSpinner } = uiSlice.actions;
export default uiSlice.reducer;
