import { configureStore, combineReducers } from '@reduxjs/toolkit';
import userReducer from './user/userSlice';
import homeReducer from './home/homeSlice';
import clientReducer from './client/clientSlice';
import uiReducer from './ui/uiSlice';
import homeClientDetails from './home/homeClientDetailsSlice';
import homeSimilarItems from './home/homeSimilarItemsSlice';

const reducer = combineReducers({
  user: userReducer,
  home: homeReducer,
  homeClientDetails: homeClientDetails,
  homeSimilarItems: homeSimilarItems,
  client: clientReducer,
  ui: uiReducer
});

export const store = configureStore({
  reducer,
});
