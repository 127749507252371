export const sortBy = (data, field, type = 'ASC') => {
  if (data === null || data === undefined || data === false) return data;
  if (
    data.length === 0 ||
    !Object.prototype.hasOwnProperty.call(data[0], field)
  )
    return data;
  let orderedData = [...data].sort((a, b) =>
    a[field] > b[field] ? 1 : b[field] > a[field] ? -1 : 0
  );
  if (type === 'DESC') return orderedData.reverse();
  return orderedData;
};
