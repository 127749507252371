import { Row, Stack } from 'react-bootstrap';
import { FaFileInvoiceDollar } from 'react-icons/fa';
import { HiChartBar, HiTrendingUp } from 'react-icons/hi';
import ClientDetailsOverviewBox from './ClientDetailsOverviewBox';
import useClientDetailsOverview from './useClientDetailsOverview';

const ClientDetailsOverview = () => {
  const { handleClickCollapseOverview, overview } = useClientDetailsOverview();

  const {
    startDate,
    endDate,
    isOverviewCollapsed,
    volume,
    rebateSavings,
    deviationSavings,
    topOpportunities,
  } = overview;

  let dollarUSLocale = Intl.NumberFormat('en-US');

  const overviewBoxes = [
    {
      className: 'pe-2',
      title: 'Volume',
      value: dollarUSLocale.format(volume),
      icon: <HiChartBar size='3.25em' color='var(--bs-primary)' />,
    },
    {
      className: 'ps-1 pe-2',
      title: 'Rebate Savings',
      value: '$' + rebateSavings,
      icon: <FaFileInvoiceDollar size='3em' color='var(--bs-primary)' />,
    },
    {
      className: 'ps-1 pe-2',
      title: 'Deviation Savings',
      value: '$' + deviationSavings,
      icon: <HiTrendingUp size='3.25em' color='var(--bs-primary)' />,
    },
    {
      type: 'list',
      className: 'ps-1',
      title: 'Top 5 Opportunities',
      value:  topOpportunities.map(item => ({
        name: item.product,
        link: `item/${item.platformId}/${item.productId}`
      })),
      icon: <HiChartBar size='3.25em' color='var(--bs-primary)' />,
    },
  ];

  return (
    <div className='mt-3 mb-3'>
      <Stack direction='horizontal' className='mb-3'>
        <div className='fw-bold' style={{ lineHeight: 1 }}>
          <span className='fs-20'>Overview</span>
          <br />
          <span>
            {startDate} - {endDate}
          </span>
        </div>
        <div
          className='link fs-16 fw-bold ms-auto'
          role='button'
          onClick={handleClickCollapseOverview}
        >
          {isOverviewCollapsed ? 'Show More' : 'Show Less'}
        </div>
      </Stack>
      <Row gap={3} direction='horizontal' className='g-2'>
        {overviewBoxes.map((box, index) => (
          <ClientDetailsOverviewBox
            key={index}
            {...box}
            isOverviewCollapsed={isOverviewCollapsed}
          />
        ))}
      </Row>
    </div>
  );
};

export default ClientDetailsOverview;
