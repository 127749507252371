import { Container } from 'react-bootstrap';
import ClientDetailsBreadcrumbs from './ClientDetailsBreadcrumbs';
import ClientDetailsFilters from './ClientDetailsFilters/ClientDetailsFilters';
import ClientDetailsOverview from './ClientDetailsOverview/ClientDetailsOverview';
import ClientDetailsTable from './ClientDetailsTable/ClientDetailsTable';
import useClientDetails from './useClientDetails';

const ClientDetails = () => {
  const {
    clientDetailsFilters,
    selectedClient,
    fetchItems,
    searchItems,
    platformId
  } = useClientDetails();

  const clientDetailsFiltersProps = {
    clientDetailsFilters,
    selectedClient,
    fetchItems,
    searchItems,
  };

  return (
    <Container fluid className='px-5 py-3 main-container bg-gray'>
      <ClientDetailsBreadcrumbs selectedClient={selectedClient} />
      <ClientDetailsOverview />
      <div className='border border-gray-light bg-white shadow-box mt-2'>
        <ClientDetailsFilters platformId={platformId} {...clientDetailsFiltersProps} />
        <ClientDetailsTable />
      </div>
    </Container>
  );
};

export default ClientDetails;
