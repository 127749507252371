import { Container, Col, Navbar } from 'react-bootstrap';

const Header = () => {
  return (
    <Navbar bg='primary' className='p-0' style={{height: '60px'}}>
      <Container fluid className='px-5 h-100'>
        <Col className='h-100 d-flex justify-content-center'>
          <img src='https://rebate-portal.s3.amazonaws.com/lops/be-white.png' alt='BE Logo' className='h-100' />
        </Col>
      </Container>
    </Navbar>
  );
}

export default Header;
