import { Route, Routes, Navigate } from 'react-router-dom';
import Login from './Login/Login';
import Register from './Register/Register';
import ChangePassword from './ChangePassword/ChangePassword';
import ForgotPassword from './ForgotPassword/ForgotPassword';
import Redirect from './Redirect/Redirect';

const UnAuthRouter = () => {
  return (
    <Routes>
      <Route path='/' element={<Login />} />
      <Route path='/register' element={<Register />} />
      <Route path='/redirect' element={<Redirect />} />
      <Route path='/forgot-password' element={<ForgotPassword />} />
      <Route path='/change-password' element={<ChangePassword />} />
      <Route path="*" element={<Navigate replace to="/" />} />
    </Routes>
  );
}

export default UnAuthRouter;
