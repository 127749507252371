import { Col, Stack, Button } from 'react-bootstrap';
import { FaChevronDown } from 'react-icons/fa';
import { useSelector } from 'react-redux';

const ItemDescription = (props) => {
  const { handleClickExportButton } = props;
  const { selectedItem, isLoading, isError } = useSelector(state => state.homeSimilarItems);

  const getStatusMessage = () => {
    if (isLoading) return 'Loading ...';
    if (isError)
      return (
        <>
          Something went wrong, please
          <br />
          <span
            role='button'
            className='link'
            onClick={() => window.location.reload()}
          >
            reload the page
          </span>
        </>
      );
    if (selectedItem === null)
      return 'We had problems displaying the information, please try with another item.';
  };

  return (
    <>
      <div className='mt-2 border border-gray-light bg-white shadow-box'>
        <Stack className='fs-16 fw-bold m-2 text-capitalize' direction='horizontal'>
          <Col>{selectedItem ? selectedItem.product.toLowerCase() : 'Item Description'}</Col>
          <Col className='mw-fc'>
            <Button variant='primary' style={{ width: '83px' }} onClick={handleClickExportButton}>
              Export <FaChevronDown />
            </Button>
          </Col>
        </Stack>
        <Stack
          direction='horizontal'
          className='py-3 text-uppercase bg-gray border-top border-gray-lighter fw-bold fs-12 py-2 ps-2'
        >
          <Col xs={1}>Min</Col>
          <Col xs={2}>Item Description</Col>
          <Col xs={2}>Manufacturer</Col>
          <Col xs={1}>Brand</Col>
          <Col xs={1}>Exclusions</Col>
          <Col xs={1}>Pack size</Col>
          <Col xs={1}>Quantity</Col>
          <Col xs={1}>Avg case price</Col>
          <Col xs={1}>Volume</Col>
          <Col xs={1}>On GPO Contract</Col>
        </Stack>
        {(!isLoading && !isError && !!selectedItem) ? (
          <Stack direction='horizontal' className='hoverable border-top p-2'>
            <Col xs={1}>{selectedItem.min}</Col>
            <Col xs={2} className='text-capitalize'>
              {selectedItem.product && selectedItem.product.toLowerCase()}
            </Col>
            <Col xs={2}>{selectedItem.manufacturer}</Col>
            <Col xs={1}>{selectedItem.brand}</Col>
            <Col xs={1}>{selectedItem.exclusions ? 'Yes' : 'No'}</Col>
            <Col xs={1}>{selectedItem.packsize}</Col>
            <Col xs={1}>{selectedItem.quantity}</Col>
            <Col xs={1}>
              {selectedItem.purchase &&
                selectedItem.quantity &&
                '$' +
                (selectedItem.purchase / selectedItem.quantity)
                  .toFixed(2)
                  .toLocaleString('en-US')}
            </Col>
            <Col xs={1}>
              {selectedItem.purchase &&
                '$' + selectedItem.purchase.toFixed(2).toLocaleString('en-US')}
            </Col>
            <Col xs={1}>{selectedItem.gpoContract ? 'Yes' : 'No'}</Col>
          </Stack>
        ) : (
          <div className='text-center border p-2'>
            {getStatusMessage()}
          </div>
        )}
      </div>
    </>
  );
};

export default ItemDescription;
