import { Stack, Tooltip, OverlayTrigger } from 'react-bootstrap';
import { FaCheck, FaChevronRight, FaTimes, FaInfoCircle } from 'react-icons/fa';
import { Link } from 'react-router-dom';

const HomeListClientRow = ({activeHomeTab, ...props}) => {
  const getCompliantVolumePercentageTextColor = (compliantVolumePercentage) => {
    if (compliantVolumePercentage < 10) return 'text-red';
    if (compliantVolumePercentage >= 10 && compliantVolumePercentage < 25)
      return 'text-yellow-dark';
    if (compliantVolumePercentage >= 25) return 'text-green';
  };

  const getCompliantVolumePercentageText = (compliantVolumePercentage) => {
    if (compliantVolumePercentage < 15) return '<15%';
    if (compliantVolumePercentage >= 15 && compliantVolumePercentage <= 25) return '15-25%';
    return '+25%';
  }
 
  return (
    <Stack direction='horizontal' className='border-top lh-sm hoverable'>
      <Stack gap={1} className='me-auto text-start px-3 py-3'>
        <span className='fs-16 fw-bold'>{props.client}</span>
        <span className='text-gray-text'>{props.platformId}</span>
        <span className='text-gray-text'>{props.subscriptionLevel}</span>
      </Stack>
      <Stack gap={1} className='text-end py-3'>
        <div className='fs-16'>
          <OverlayTrigger
            placement='top'
            delay={{ show: 250, hide: 400 }}
            overlay={
              <Tooltip id="button-tooltip">
                Program Participation Rate: {getCompliantVolumePercentageText(props.compliantVolumePercentage)}
              </Tooltip>
            }
          >
            <span className='px-2 clickable'>
              <FaInfoCircle color='var(--bs-primary)' size={12} />
            </span>
          </OverlayTrigger>
           Compliant Volume %{' '}
          <span
            className={
              'fs-20 fw-bold ' +
              getCompliantVolumePercentageTextColor(
                props.compliantVolumePercentage
              )
            }
          >
            {props.compliantVolumePercentage}%
          </span>
        </div>
        <Stack
          direction='horizontal'
          gap={1}
          className='ms-auto text-gray-text'
        >
          <span>${props.compliantVolume.toLocaleString('en-US')}</span>{' '}
          <FaCheck color={'var(--bs-green)'} />
        </Stack>
        <Stack
          direction='horizontal'
          gap={1}
          className='ms-auto text-gray-text'
        >
          <span>${props.nonCompliantVolume.toLocaleString('en-US')}</span>
          <FaTimes color={'var(--bs-red)'} />
        </Stack>
      </Stack>
      <Link
        to={`/dashboard/${activeHomeTab === 'allClients' ? 'allClient' : 'client'}/${props.platformId}`}
        className='ms-3 px-3 align-self-stretch d-flex align-items-center'
      >
        <FaChevronRight color='var(--bs-dark)' />
      </Link>
    </Stack>
  );
};

export default HomeListClientRow;