import { FaSearch } from 'react-icons/fa';
import AsyncSelect from 'react-select/async';
import Select from 'react-select';
import useHomeFiltersSearch from './useHomeFiltersSearch';
import { Row, Col } from 'react-bootstrap';

const HomeFiltersSearch = () => {
  const {
    allClientsSearch, myClientsSearch,
    activeHomeTab, myClientsSelected, clearMyClientsSearch
  } = useHomeFiltersSearch();

  return activeHomeTab === 'myClients' ? (
    <>
      <Row>
        <Col className='position-relative'>
          <FaSearch className='zi-1 mx-3 pe-none text-gray-light position-absolute start-5 top-50 translate-middle-y' />
          <Select {...myClientsSearch} />
          {
            myClientsSelected.length > 0 &&
            <span onClick={clearMyClientsSearch} className='text-tags clickable fs-12 position-absolute top-50 end-20 translate-middle fw-bold'>CLEAR</span>
          }
        </Col>
      </Row>
      {
        myClientsSelected.length > 0 && 
        <Row className='gx-2 gy-1 my-1'>
          {myClientsSelected}
        </Row>
      }
    </>
  ) : (
    <Row>
      <Col className='position-relative'>
        <FaSearch className='zi-1 mx-3 pe-none text-gray-light position-absolute start-5 top-50 translate-middle-y' />
        <AsyncSelect {...allClientsSearch} />
      </Col>
    </Row>
  );
};

export default HomeFiltersSearch;
