import { useDispatch, useSelector } from 'react-redux';
import {
  loadMoreClientDetailsItems,
  setClientDetailsSorting,
} from '../../../Reducers/home/homeClientDetailsSlice';

const useClientDetailsTable = () => {
  const dispatch = useDispatch();

  const { clientDetailsItems, clientDetailsSorting, pagination } = useSelector(
    (state) => state.homeClientDetails
  );
  const { totalItems } = pagination;

  /**
   * @desc For pagination
   */
  const loadMoreItems = () => {
    setTimeout(() => {
      dispatch(loadMoreClientDetailsItems());
    }, 250);
  };

  const processSorting = ({ field, type }) => {
    dispatch(setClientDetailsSorting({ field, type }));
  };

  return {
    clientDetailsItems,
    clientDetailsSorting,
    totalItems,
    loadMoreItems,
    processSorting,
  };
};

export default useClientDetailsTable;
