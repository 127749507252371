import { useEffect, useState } from 'react';
import { FormControl, InputGroup } from 'react-bootstrap';
import { FaSearch } from 'react-icons/fa';
import { useDispatch } from 'react-redux';
import { setClientDetailsFilters } from '../../../Reducers/home/homeClientDetailsSlice';

const ClientDetailsFiltersSearch = ({ search, searchItems }) => {
  const [searchTimer, setSearchTimer] = useState(null);

  const dispatch = useDispatch();

  const handleChangeSearchInput = (e) => {
    const searchValue = e.target.value.toLowerCase();
    dispatch(setClientDetailsFilters({ search: searchValue }));

    clearTimeout(searchTimer);
    setSearchTimer(
      setTimeout(() => {
        searchItems();
      }, 250)
    );
  };

  useEffect(() => {
    return () => clearTimeout(searchTimer);
  }, []);

  return (
    <InputGroup className='position-relative'>
      <FaSearch
        className='zi-1 pe-none position-absolute start-5 top-50 translate-middle-y'
        color='var(--bs-gray-text)'
      />
      <FormControl
        className='ps-5 shadow-none'
        placeholder='Search for products...'
        value={search ?? ''}
        onChange={handleChangeSearchInput}
      />
    </InputGroup>
  );
};

export default ClientDetailsFiltersSearch;
