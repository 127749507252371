import { memo } from 'react';
import { Col, Spinner, Stack } from 'react-bootstrap';
import { useQuery } from 'react-query';
import homeApi from '../../api/homeApi';

const fetchCall = async ({ queryKey }) => {
  const params = queryKey[1];
  const res = await homeApi.itemMatch.interested.post(params);
  return res.data;
};

const SimilarItemsTableRow = ({item, merchant}) => {
  const params = {
    match: {
      matchManufacturerPlatformId: item.manufacturerPlatformId,
      matchMin: item.min,
      matchProductDescription: item.product,
      matchProductQuantity: item.quantity,
      rebate: item.rebate
    },
    merchant
  };

  const { refetch, isIdle, isSuccess } = useQuery({
    queryKey: ['homeApi.itemMatch.interested.post', params],
    queryFn: fetchCall,
    refetchOnWindowFocus: false,
    enabled: false
  })

  const onInterested = () => {
    if (isIdle) {
      refetch();
    }
  };

  return (
    <Stack direction='horizontal' className='hoverable border-top p-2' >
      <Col xs={1}>{item.min}</Col>
      <Col xs={2} className='text-capitalize'>
        {item.product.toLowerCase()}
      </Col>
      <Col xs={2}>{item.manufacturer}</Col>
      <Col xs={2}>{item.brand}</Col>
      <Col xs={1} className='ps-3'>
        {item.packsize}
      </Col>
      <Col xs={1} className='ps-3'>
        {item.quantity}
      </Col>
      <Col xs={1}>
        {item.rebate &&
          '$' + item.rebate.toFixed(2).toLocaleString('en-US')}
      </Col>
      <Col xs={1}>
        {item.deviationValue &&
          '$' + item.deviationValue.toFixed(2).toLocaleString('en-US')}
      </Col>
      <Col xs={1} className={isIdle && 'text-secondary'} onClick={() => onInterested(item)}>
        {
          isSuccess ? <span>Sent</span> :( isIdle ? <span className='clickable'>Interested</span> : <Spinner animation='border' size='sm' color='secondary' />)
        }
      </Col>
    </Stack>
  );
};

export default memo(SimilarItemsTableRow);
