import axios from 'axios';
import { useEffect, useState } from 'react';
import initFullStory from '../../utils/fullstory';

export const ApiInstance = axios.create({baseURL:  'localhost'});

ApiInstance.defaults.headers['Pragma'] = 'no-cache';
ApiInstance.defaults.headers.post['Content-Type'] = 'application/json; charset=UTF-8';
// eslint-disable-next-line no-undef
axios.defaults.baseURL = process.env.REACT_APP_API_URL;

const authKey = localStorage.getItem('authKey');
if (authKey) {
  axios.defaults.headers.common['Authorization'] = `Bearer ${authKey}`;
}

export const setAuthKey = authKey => {
  if (authKey) {
    axios.defaults.headers.common['Authorization'] = `Bearer ${authKey}`;
  } else {
    delete axios.defaults.headers.common['Authorization'];
  }
};

const useAxios = () => {
  const [ready, setReady] = useState(false);
  const [authError, setAuthError] = useState(false);

  useEffect(() => {
    ApiInstance.interceptors.response.use(response => {
      return response;
    }, error => {
      if (error.response && error.response.status === 401) {
        setAuthError(true);
      }
      if (error.response) {
        return Promise.reject(error.response);
      } else {
        return Promise.reject(error);
      }
    });
    initFullStory();
    setReady(true);
    // eslint-disable-next-line
  }, []);

  return {
    ready, authError
  };
};

export default useAxios;
