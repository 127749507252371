export default {
    azureSSO: {
        auth: {
            clientId: 'fb6893df-c01a-47de-a7ac-44f556cbb592',
            authority: 'https://login.microsoftonline.com/5c78d246-8728-42ae-963f-3907f14cadcd'
        }
    },
    colorPalette: [
        '#FA9016', '#334F9A', '#8A43A4', '#FB4726', '#26C0FB',
        '#C2185B', '#827717', '#37474F', '#FF5722', '#43A047',
        '#009688', '#7986CB', '#A1887F', '#00ACC1', '#424242',
        '#FFD600', '#B71C1C', '#78909C', '#EF5350', '#212121',
        '#7B1FA2', '#795548', '#BA68C8','#BF360C', '#5E35B1',
        '#FF7043', '#7C4DFF','#283593', '#FF6D00', '#FFAB00',
        '#FF9800', '#304FFE', '#FFCA28', '#2196F3', '#FFEA00',
        '#0091EA', '#03A9F4', '#00695C', '#388E3C', '#2E7D32',
        '#558B2F', '#33691E', '#455A64','#546E7A', '#607D8B',
        '#616161', '#757575', '#3E2723', '#5D4037', '#6D4C41',
        '#8D6E63', '#FF3D00', '#D84315',  '#DD2C00','#E64A19',
        '#F4511E', '#1B5E20', '#4CAF50', '#00897B', '#00796B',
        '#004D40', '#006064', '#00838F', '#0097A7', '#FF1744',
        '#00BCD4', '#039BE5', '#0288D1', '#0277BD', '#01579B',
        '#1E88E5', '#1976D2', '#1565C0', '#0D47A1', '#448AFF',
        '#263238', '#2979FF', '#FF4081', '#5C6BC0', '#3F51B5',
        '#3949AB', '#536DFE', '#4E342E'
    ]
};
