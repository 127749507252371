import moment from 'moment';
import { useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import HomeOverviewBox from './HomeOverviewBox';
import building from '../../../assets/img/building.svg';
import clipboard from '../../../assets/img/clipboard.svg';
import { useSelector } from 'react-redux';
import HomeOverviewGraph from './HomeOverviewGraph';
//import randomColor from '../../../utils/randomColor';

const HomeOverview = () => {
	const [showMore, setShowMore] = useState(true);
	const startDate = moment().subtract(1, 'month').startOf('month').format('MM/DD/YYYY');
	const endDate = moment().startOf('month').format('MM/DD/YYYY');

	const {
		totalClients, complianceRate, graphs
	} = useSelector(state => state.home.overviewStats);
	
	return (
		<Row className='mb-3'>
			<Col>
				<Row className='align-items-center'>
					<Col>
						<Row>
							<Col className='fw-bold fs-20'>Overview</Col>
						</Row>
						<Row>
							<Col className='fw-bold fs-14'>
								{`${startDate} - ${endDate}`}
							</Col>
						</Row>
					</Col>
					<Col className='text-secondary mw-fc clickable' onClick={() => setShowMore(!showMore)}>
						Show {`${showMore ? 'Less' : 'More'}`}
					</Col>
				</Row>
				<Row className='align-items-stretch'>
					<HomeOverviewBox expand={showMore} title='Total Clients' value={totalClients} img={building} />
					<HomeOverviewBox expand={showMore} title='Compliance Rate' value={complianceRate} img={clipboard} />
				</Row>
				{
					showMore &&
					<Row className='mt-3 align-items-stretch'>
						<HomeOverviewGraph descriptionXS={6} data={graphs.clients} title='Top Clients' headers={['Rebates', 'Deviations']} />
						<HomeOverviewGraph descriptionXS={5} data={graphs.opportunity} title='Top Opportunities' headers={['Item', 'Potential Savings']} />
					</Row>
				}
			</Col>
		</Row>
	);
};

export default HomeOverview;