import { memo } from 'react';
import { Col, Stack, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { FaSort, FaSortDown, FaSortUp, FaInfoCircle } from 'react-icons/fa';
import InfiniteScroll from 'react-infinite-scroll-component';
import ClientDetailsTableRow from './ClientDetailsTableRow';
import useClientDetailsTable from './useClientDetailsTable';

const ClientDetailsTable = () => {
  const {
    clientDetailsItems,
    clientDetailsSorting,
    totalItems,
    loadMoreItems,
    processSorting,
  } = useClientDetailsTable();

  const handleClickSortBy = (field) => {
    let sortField, sortType;

    if (clientDetailsSorting.field === field) {
      if (clientDetailsSorting.type === 'ASC') {
        sortField = field;
        sortType = 'DESC';
      } else {
        sortField = null;
        sortType = null;
      }
    } else {
      sortField = field;
      sortType = 'ASC';
    }

    processSorting({ field: sortField, type: sortType });
  };

  const getOrderIcon = (field) => {
    return clientDetailsSorting.field === field ? (
      clientDetailsSorting.type === 'ASC' ? (
        <FaSortUp />
      ) : (
        <FaSortDown />
      )
    ) : (
      <FaSort />
    );
  };

  const getStatusMessage = () => {
    if (clientDetailsItems === null) return 'Loading ...';
    if (clientDetailsItems === false)
      return (
        <>
          Something went wrong, please
          <br />
          <span
            role='button'
            className='link'
            onClick={() => window.location.reload()}
          >
            reload the page
          </span>
        </>
      );
    if (clientDetailsItems && clientDetailsItems.length === 0)
      return 'We did not find any results, please try with different filters.';
  };

  return (
    <>
      <Stack
        direction='horizontal'
        className='text-uppercase bg-gray border border-gray-lighter py-2 ps-2 fw-bold fs-12 sticky-top'
        style={{ paddingRight: '18px' }}
      >
        <Col xs={1}>Min
          <OverlayTrigger
            placement={'bottom'}
            overlay={
              <Tooltip id={`tooltip-min`}>
                Manufacturer Item Number: The number used by the manufacturer to identify the item
              </Tooltip>
            }
          >
            <span className='px-1 clickable'>
              <FaInfoCircle color='var(--bs-primary)' />
            </span>
          </OverlayTrigger>
        </Col>
        <Col xs={1}>Item Description</Col>
        <Col xs={1}>Manufacturer </Col>
        <Col xs={1}>Brand</Col>
        <Col xs={1}>
          <Stack direction='horizontal' gap={1}>
            <Stack
              direction='horizontal'
              role='button'
              onClick={() => handleClickSortBy('exclusions')}
            >
              {getOrderIcon('exclusions')}
            </Stack>
            Exclusions
            <OverlayTrigger
              placement={'bottom'}
              overlay={
                <Tooltip id={`tooltip-exclusions`}>
                  Account is Excluded from using MAP Contract and/or Rebate – From Salesforce records
                </Tooltip>
              }
            >
              <span className='clickable'>
                <FaInfoCircle color='var(--bs-primary)' />
              </span>
            </OverlayTrigger>
          </Stack>
        </Col>
        <Col xs={1}>
          <Stack direction='horizontal' gap={1}>
            <Stack
              direction='horizontal'
              role='button'
              onClick={() => handleClickSortBy('packsize')}
              className='ms-auto'
            >
              {getOrderIcon('packsize')}
            </Stack>
            Pack size
            <Stack
              direction='horizontal'>
              &nbsp;&nbsp;&nbsp;
            </Stack>
          </Stack>
        </Col>
        <Col xs={1}>
          <Stack direction='horizontal' gap={1}>
            <Stack
              direction='horizontal'
              role='button'
              onClick={() => handleClickSortBy('quantity')}
              className='ms-auto'
            >
              {getOrderIcon('quantity')}
            </Stack>
            Quantity
            <Stack
              direction='horizontal'>
              &nbsp;&nbsp;&nbsp;
            </Stack>
          </Stack>
        </Col>
        <Col xs={1}>
          <Stack direction='horizontal' gap={1}>
            <Stack
              direction='horizontal'
              role='button'
              onClick={() => handleClickSortBy('uom')}
            >
              {getOrderIcon('uom')}
            </Stack>
            UOM
          </Stack>
        </Col>
        <Col xs={1}>
          <Stack direction='horizontal' gap={1}>
            <Stack
              direction='horizontal' className='ms-auto'>
              Avg case price
            </Stack>            
            <Stack
              direction='horizontal'>
              &nbsp;&nbsp;&nbsp;
            </Stack>
          </Stack>
        </Col>
        <Col xs={1}>
          <Stack direction='horizontal' gap={1}>
            <Stack
              direction='horizontal'
              role='button'
              onClick={() => handleClickSortBy('purchase')}
              className='ms-auto'
            >
              {getOrderIcon('purchase')}
            </Stack>
            Volume
            <Stack
              direction='horizontal'>
              &nbsp;&nbsp;&nbsp;
            </Stack>
          </Stack>
          
        </Col>
        <Col xs={1}>
          <Stack direction='horizontal' gap={1}>
            <Stack
              direction='horizontal'
              role='button'
              onClick={() => handleClickSortBy('gpoContract')}
            >
              {getOrderIcon('gpoContract')}
            </Stack>
            On GPO Contract
            <OverlayTrigger
              placement={'bottom'}
              overlay={
                <Tooltip id={`tooltip-gpo`}>
                  Account is purchasing current item on MAP Contract and/or Rebate (item is compliant)
                </Tooltip>
              }
            >
              <i className='clickable'>
                <FaInfoCircle color='var(--bs-primary)' />
              </i>
            </OverlayTrigger>
          </Stack>
        </Col>
        <Col xs={1}>
          <Stack direction='horizontal' gap={1}>
            <Stack
              direction='horizontal'
              role='button'
              onClick={() => handleClickSortBy('similarItems')}
            >
              {getOrderIcon('similarItems')}
            </Stack>
            Similiar items
          </Stack>
        </Col>
      </Stack>
      <div
        // id='scrollableDiv'
        className='home-client-detail-items-container'
      >
        {clientDetailsItems && clientDetailsItems.length > 0 ? (
          <InfiniteScroll
            dataLength={clientDetailsItems.length}
            next={loadMoreItems}
            hasMore={clientDetailsItems.length < totalItems}
            loader={
              <div className='text-center p-2 border-top text-gray-text'>
                Loading ...
              </div>
            }
            // scrollableTarget='scrollableDiv'
            endMessage={
              <div className='text-center p-2 border-top text-gray-text'>
                No more items
              </div>
            }
            scrollThreshold={1}
          >
            {clientDetailsItems.map((item) => (
              <ClientDetailsTableRow
                item={item}
                key={item.manufacturerProductId}
              />
            ))}
          </InfiniteScroll>
        ) : (
          <div className='text-center p-2'>{getStatusMessage()}</div>
        )}
      </div>
    </>
  );
};

export default memo(ClientDetailsTable);
