import { Col, Container, Row } from 'react-bootstrap';
import { Outlet } from 'react-router-dom';
import HomeFilters from './HomeFilters/HomeFilters';
import HomeOverview from './HomeOverview/HomeOverview';
import useHome from './useHome';

const Home = () => {
  const {
    clientsFilters,
    fetchClients,
    isListActive,
    activeHomeTab,
    handleClickTab,
    processSorting,
    handleChangeStartDate,
    handleChangeEndDate,
    infiniteClientsList
  } = useHome();

  const homeFilterProps = {
    clientsFilters,
    fetchClients,
    isListActive,
    activeHomeTab,
    processSorting,
    handleChangeStartDate,
    handleChangeEndDate,
  };

  return (
    <Container fluid className='px-5 py-3 main-container bg-gray'>
      <HomeOverview />
      <Row>
        <Col
          className={
            'fs-20 p-0 mx-2 ' +
            (activeHomeTab === 'myClients' ? 'fw-bold underlined' : '')
          }
          xs='auto'
          role='button'
          onClick={() => handleClickTab('myClients')}
        >
          My Clients
        </Col>
        <Col
          className={
            'fs-20 p-0 mx-2 ' +
            (activeHomeTab === 'allClients' ? 'fw-bold underlined' : '')
          }
          xs='auto'
          role='button'
          onClick={() => handleClickTab('allClients')}
        >
          All Clients
        </Col>
      </Row>
      <div className='border border-gray-light bg-white shadow-box mt-2'>
        <HomeFilters {...homeFilterProps} {...clientsFilters} />
        <Outlet context={{ processSorting, infiniteClientsList }} />
      </div>
    </Container>
  );
};

export default Home;
