import { useDispatch, useSelector } from 'react-redux';
import { setOverview } from '../../../Reducers/home/homeClientDetailsSlice';

const useClientDetailsOverview = () => {
  const { overview } = useSelector((state) => state.homeClientDetails);
  const dispatch = useDispatch();

  const handleClickCollapseOverview = () => {
    dispatch(
      setOverview({ isOverviewCollapsed: !overview.isOverviewCollapsed })
    );
  };

  return {
    handleClickCollapseOverview,
    overview,
  };
};

export default useClientDetailsOverview;
