import { useDispatch } from 'react-redux';
import { setClientDetailsFilters } from '../../../Reducers/home/homeClientDetailsSlice';

const useClientDetailsFilters = () => {
  const dispatch = useDispatch();

  const handleClickStartDate = (date) => {
    dispatch(setClientDetailsFilters({ startDate: date.toISOString() }));
  };

  const handleClickEndDate = (date) => {
    dispatch(setClientDetailsFilters({ endDate: date.toISOString() }));
  };

  const handleClickNonCompliant = (compliant) => {
    dispatch(setClientDetailsFilters({ isNonCompliant: compliant }));
  };

  const handleApiCall = (call) => {
    dispatch(setClientDetailsFilters({ apiCall: call }));
  };


  return { handleClickStartDate, handleClickEndDate, handleClickNonCompliant, handleApiCall };
};

export default useClientDetailsFilters;
