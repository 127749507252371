import { createSlice } from '@reduxjs/toolkit';
import { endOfMonth, startOfMonth, subMonths } from 'date-fns';
import { sortBy } from '../../utils/sort';
import numeral from 'numeral';
import randomColor from '../../utils/randomColor';

const previousMonth = subMonths(new Date(), 1);
const initialState = {
  myClients: [],
  clients: [],
  isLoading: false,
  isLoadingAllClients: false,
  overviewStats: {
    complianceRate: '...',
    totalClients: '...',
    graphs: {
      clients: [],
      opportunity: []
    }
  },
  isError: false,
  clientsFilters: {
    startDate: startOfMonth(previousMonth).toISOString(),
    endDate: endOfMonth(previousMonth).toISOString(),
    clients: [],
    showVolume: true,
  },
  myClientsSearch: [],
  allClientsSearchItem: null,
  clientsSorting: {
    field: 'compliantVolumePercentage',
    type: 'DESC',
  },
  activeHomeTab: 'myClients',
};

const updateMyClients = (state) => {
  state.isError = false;
  const filteredClients = state.myClients.filter(c => (
    state.myClientsSearch.length > 0 ? state.myClientsSearch.map(c => c.label).includes(c.client) : true
  ));

  const sortedClients = filteredClients.length > 0 ? sortBy(
    filteredClients,
    state.clientsSorting.field,
    state.clientsSorting.type,
  ) : [];

  state.clients = sortedClients;
};

const homeSlice = createSlice({
  name: 'home',
  initialState,
  reducers: {
    clearClients: (state) => {
      state.clients = [];
      state.isError = false;
      state.isLoading = false;
    },
    selectAllClientsSearchItem: (state, action) => {
      state.isError = false;
      state.isLoadingAllClients = true;
      state.allClientsSearchItem = action.payload;
    },
    setAllClients: (state, action) => {
      state.clients = action.payload;
      state.isError = false;
      state.isLoadingAllClients = false;
      state.isLoading = false;
    },
    updateClients: (state) => {
      updateMyClients(state);
    },
    resetMyClients: (state, action) => {
      state.myClients = [];
      state.clients = [];
      state.isError = false;
      state.isLoading = action.payload ? action.payload : false;
      state.overviewStats= {
        complianceRate: '...',
        totalClients: '...',
        graphs: {
          clients: [],
          opportunity: []
        }
      }
    },
    setIsLoading: (state, action) => {
      state.isLoading = action.payload;
      state.isError = action.payload === true ? false : state.isError;
    },
    setError: (state) => {
      state.isLoading = false;
      state.isError = true;
    },
    setMyClients: (state, action) => {
      state.myClients = action.payload.clients;
      state.overviewStats = {
        complianceRate: numeral(action.payload.complianceRatePercentage).format('0,0.00')+'%',
        totalClients: action.payload.totalClients,
        graphs: {
          clients: action.payload.graph.clients.sort((a, b) => {
            return b.rebate - a.rebate
          }).map((c, i) => ({
            name: c.name,
            y: c.rebate,
            data: [numeral(c.rebate).format('$0,0.00'), numeral(c.deviation).format('$0,0.00')],
            color: randomColor(i)
          })),
          opportunity: action.payload.graph.opportunity.sort((a, b) => {
            return b.rebate - a.rebate;
          }).map((o, i) => ({
            name: `${o.brand} ${o.description}`,
            displayName: o.brand,
            y: o.rebate,
            data: [o.description, numeral(o.rebate).format('$0,0.00')],
            color: randomColor(i)
          }))
        }
      }
      state.isLoading = false;
      updateMyClients(state);
    },
    setAllandMyClients: (state, action) => {
      state.myClients = action.payload.myClients.clients;
      state.overviewStats = {
        complianceRate: numeral(action.payload.myClients.complianceRatePercentage).format('0,0.00')+'%',
        totalClients: action.payload.myClients.totalClients,
        graphs: {
          clients: action.payload.myClients.graph.clients.sort((a, b) => {
            return b.rebate - a.rebate
          }).map((c, i) => ({
            name: c.name,
            y: c.rebate,
            data: [numeral(c.rebate).format('$0,0.00'), numeral(c.deviation).format('$0,0.00')],
            color: randomColor(i)
          })),
          opportunity: action.payload.myClients.graph.opportunity.sort((a, b) => {
            return b.rebate - a.rebate;
          }).map((o, i) => ({
            name: `${o.brand} ${o.description}`,
            displayName: o.brand,
            y: o.rebate,
            data: [o.description, numeral(o.rebate).format('$0,0.00')],
            color: randomColor(i)
          }))
        }
      }
      state.clients = action.payload.allClients;
      state.isError = false;
      state.isLoadingAllClients = false;
      state.isLoading = false;
    },
    setClients: (state, action) => {
      state.clients = action.payload;
    },
    setClientsFilters: (state, action) => {
      state.clientsFilters = { ...state.clientsFilters, ...action.payload };
    },
    addMyClientToSearch: (state, action) => {
      state.myClientsSearch.push(action.payload);
      state.isLoading = false;
      updateMyClients(state);
    },
    removeMyClientToSearch: (state, action) => {
      const index = state.myClientsSearch.findIndex(c => c.label === action.payload.label);
      state.myClientsSearch.splice(index, 1);
      state.isLoading = false;
      updateMyClients(state);
    },
    clearMyClientToSearch: (state) => {
      state.myClientsSearch = [];
      state.isLoading = false;
      updateMyClients(state);
    },
    setClientsSorting: (state, action) => {
      state.clientsSorting = { ...state.clientsSorting, ...action.payload };
      if (state.activeHomeTab === 'myClients') {
        state.isLoading = false;
        updateMyClients(state);
      }
    },
    setActiveHomeTab: (state, action) => {
      state.activeHomeTab = action.payload;
    },
  },
});

export const {
  setAllandMyClients,
  clearClients,
  updateClients,
  resetMyClients,
  setMyClients,
  setClients,
  setClientsFilters,
  addMyClientToSearch,
  removeMyClientToSearch,
  clearMyClientToSearch,
  setClientsSorting,
  setActiveHomeTab,
  setIsLoading,
  setError,
  selectAllClientsSearchItem,
  setAllClients
} = homeSlice.actions;
export default homeSlice.reducer;
