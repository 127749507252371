import { msalConfig } from './config/msalConfig.js'
import { PublicClientApplication, InteractionRequiredAuthError } from '@azure/msal-browser';
import LocalStorage from './Components/LocalStorage/LocalStorage';
import authApi from './api/authApi';
const instance = new PublicClientApplication(msalConfig);

// Redirect: once login is successful and redirects with tokens, call Graph API
instance.handleRedirectPromise().then(handleResponse).catch(err => {
    console.error(err);
});

function handleResponse(res) {
    if (res !== null) {
        // console.log({ res });
        instance.setActiveAccount(res.account);
        authApi.login.post({ token: res.accessToken }).then(({ data }) => {
            const { token } = data;
            LocalStorage.authKey.set(token);
            LocalStorage.userData.set(data);
            setTimeout(() => {
                window.location.reload();
            }, 500)
            console.log('successful login');
        }).catch(err => {
            console.error(err);
            // navigate('/');
        });
    } else {
        // need to call getAccount here?
        const currentAccounts = instance.getAllAccounts();
        // console.log('!currentAccounts || currentAccounts.length < 1', !currentAccounts || currentAccounts.length < 1);
        if (!currentAccounts || currentAccounts.length < 1) {
            instance.ssoSilent().then((response) => {
                console.log({ response })
            }).catch(error => {
                console.error("Silent Error: " + error);
                if (error instanceof InteractionRequiredAuthError) {
                    instance.ssoSilent().then((response) => {
                        console.log({ response });
                    }).catch(err => {
                        console.error("Silent Error: " + err);
                        if (err instanceof InteractionRequiredAuthError) {
                            signIn("popup");
                        }
                    })
                }
            });
            return;
        } else if (currentAccounts.length > 1) {
            // Add choose account code here
        } else if (currentAccounts.length === 1) {
            const activeAccount = currentAccounts[0];
            instance.setActiveAccount(activeAccount);
        }
    }
}

async function signIn(method) {
    if (method === "popup") {
        return instance.loginPopup().then(handleResponse).catch(function(error) {
            console.log(error);
        });
    } else if (method === "redirect") {
        return instance.loginRedirect()
    }
}

function signOut(interactionType) {
    const logoutRequest = {
        account: instance.getAccountByHomeId()
    };

    if (interactionType === "popup") {
        instance.logoutPopup(logoutRequest)
    } else {
        instance.logoutRedirect(logoutRequest);
    }
}

export { signIn, signOut, instance }
