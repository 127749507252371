import { useEffect } from 'react';
import clientApi from '../../api/clientApi';
import { setSpinner } from '../../Reducers/ui/uiSlice';
import { setClientUsers, setClientUsersSelected } from '../../Reducers/client/clientSlice';
import LocalStorage from '../../Components/LocalStorage/LocalStorage';
import { setAuthKey } from '../../Components/useAxios/useAxios';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { clearClients } from '../../Reducers/home/homeSlice';
import authApi from '../../api/authApi';
import { instance } from '../../sso';
import userApi from '../../api/userApi';
import { setUserInfo } from '../../Reducers/user/userSlice';

const useClientSwitch = () => {
  const { clientUsers, clientUsersSelected } = useSelector((state) => state.client);
  // const [options, setOptions] = useState([]);
  const options = clientUsers.map(d => {
    return {
      label: `${d.name} ${d.email}`,
      value: { email: d.email, sfId: d.sfId },
    }
  });
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    dispatch(setSpinner(true));
    clientApi.getClientUsers().then(response => {
      dispatch(setSpinner(false));
      dispatch(setClientUsers(response.data));
    })
    .catch((error) => {
      console.log(error);
      dispatch(setSpinner(false));
    });

  }, []);

  const onLoginAccount = (e) => {
    e.preventDefault();
    const params = {
      userData: {
        email: clientUsersSelected.value.email,
        sfId: clientUsersSelected.value.sfId
      }
    };
    authApi.login.post(params).then(({data}) => {
      const { token } = data;
      LocalStorage.authKey.set(token)
      setAuthKey(token);
      userApi.get().then(({data}) => {
        dispatch(setUserInfo(data));
        const currentAccounts = instance.getAllAccounts();
        const acc = currentAccounts[0]?.username;
        if (acc) {
          // Fullstory Identify
          if(window['_fs_namespace']) {
            window[window['_fs_namespace']].identify(currentAccounts[0].homeAccountId, {
              userEmail: acc,
              client : data.email,
              sfId : data.sfId
            });
          }
        }
        
        navigate('/dashboard');
      }).catch(err => {
        console.log(err);
      });
    }).catch(err => {
      console.error(err);
      navigate('/');
    });
  }

  const onChange = (e) => {
    dispatch(clearClients())
    dispatch(setClientUsersSelected(e));
  }

  return {
    useClientSwitch,
    clientUsers,
    selectProps: {
      onChange,
      value: clientUsersSelected,
      options
    },
    onLoginAccount
  }
}

export default useClientSwitch;
