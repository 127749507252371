import Axios from 'axios';

// eslint-disable-next-line no-undef
const url = `${process.env.REACT_APP_API_URL}/home`;

const homeApi = {
  post: (startDate, endDate) => {
    return Axios.post(`${url}`, {
      startDate,
      endDate,
    });
  },
  platformId: (platformId) => {
    return {
      get: ({startDate, endDate}) => {
        return Axios.get(`${url}/${platformId}?startDate=${startDate}&endDate=${endDate}`);
      }
    }
  },
  merchants: {
    get: ({search}) => {
      return Axios.get(`${url}/merchants?search=${search}`);
    }
  },
  detail: {
    post: ({startDate, endDate, platformId}) => {
      return Axios.post(`${url}/detail`, {
        startDate,
        endDate,
        platformId,
      });
    },
    excel: {
      post: ({startDate, endDate, platformId}) => {
        return Axios.post(`${url}/detail/excel`, {
          startDate,
          endDate,
          platformId
        });
      },
    }
  },
  detailAll: {
    post: ({startDate, endDate, platformId}) => {
      return Axios.post(`${url}/detail-all`, {
        startDate,
        endDate,
        platformId,
      });
    }
  },
  excel: {
    post: ({startDate, endDate}) => {
      return Axios.post(`${url}/excel`, {
        startDate,
        endDate,
      });
    },
  },
  similarItems: {
    post: ({startDate, endDate, platformId, mins}) => {
      return Axios.post(`${url}/similar-items`, {
        startDate,
        endDate,
        platformId,
        mins,
      });
    }
  },
  itemMatch: {
    post: ({startDate, endDate, platformId, productId}) => {
      return Axios.post(`${url}/item-match`, {
        startDate,
        endDate,
        platformId,
        productId,
      });
    },
    excel: {
      post: ({startDate, endDate, platformId, productId}) => {
        return Axios.post(`${url}/item-match/excel`, {
          startDate,
          endDate,
          platformId,
          productId
        });
      },
    },
    interested: {
      post: ({clientManagerEmail, match, merchant}) => {
        return Axios.post(`${url}/item-match/interested`, {
          clientManagerEmail, match, merchant
        });
      }
    }
  },
  filters: {
    post: () => {
      return Axios.post(`${url}/filters`);
    }
  }
};

export default homeApi;
