import { useEffect } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';

const Redirect = () => {
  const navigate = useNavigate();
  useEffect(() => {
    console.log('navigating to /dashboard ...');
    navigate('/dashboard');
  }, [navigate]);

return (
  <Container>
    <Row>
      <Col><h1>Redirecting...</h1></Col>
    </Row>
  </Container>
);
};

export default Redirect;
