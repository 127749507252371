import {
  Button,
  Col,
  Row,
  Stack,
  ToggleButton,
  ToggleButtonGroup,
} from 'react-bootstrap';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { FaCalendar, FaList, FaTable } from 'react-icons/fa';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import Select from 'react-select';
import { selectStyles } from '../../../config/constants';
import { setAllandMyClients, setError, setClientsSorting, resetMyClients, setMyClients } from '../../../Reducers/home/homeSlice';
import HomeFiltersSearch from './HomeFiltersSearch';
import { FaChevronDown } from "react-icons/fa";
import homeApi from '../../../api/homeApi';

const HomeFilters = (props) => {
  const {
    isListActive,
    processSorting,
    handleChangeStartDate,
    handleChangeEndDate,
    startDate,
    endDate,
  } = props;

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { clientsSorting, activeHomeTab, clientsFilters, allClientsSearchItem } = useSelector(state => state.home);

  const handleClickApplyButton = () => {
    dispatch(resetMyClients(true));
    const { startDate, endDate } = clientsFilters;
    
    homeApi.post(startDate, endDate).then((response) => {
      const myClientsData = response.data;
      if (activeHomeTab === 'myClients') {
        dispatch(setMyClients(response.data));
      } else {
        homeApi.platformId(allClientsSearchItem.value).get({startDate, endDate}).then((response) => {
          dispatch(setAllandMyClients({
            allClients: response.data.clients,
            myClients: myClientsData
          }));
        }).catch((error) => {
          console.log(error);
          dispatch(setError());
        });
      }
    }).catch((error) => {
      console.log(error);
      dispatch(resetMyClients());
    });
  };

  const handleToggleView = (e) => {
    // Reset Sorting
    dispatch(setClientsSorting({ field: 'compliantVolumePercentage', type: 'DESC' }));
    navigate('/dashboard/' + e);
  };

  const sortBySelect = {
    options: [
      { label: 'Highest to Lowest', value: 'DESC' },
      { label: 'Lowest to Highest', value: 'ASC' },
    ],
    value: {
      label: clientsSorting.type === 'ASC' ? 'Lowest to Highest' : 'Highest to Lowest',
      value: clientsSorting.type,
    },
    onChange: (opt) => {
      const sortTypeValue = opt.value;
      processSorting({ field: 'compliantVolumePercentage', type: sortTypeValue });
    },
    styles: selectStyles(false),
    isSearchable: false
  };

  const handleClickExportButton = () => {
    homeApi.excel.post({startDate, endDate}).then(res => {
      window.open(res.data.location, '_blank');
    });
  };

  return (
    <Row className='my-3 mx-0 align-items-start gx-2'>
      <Col className='fw-bold' md={2} lg={3} xl={4}>
        <HomeFiltersSearch />
      </Col>
      <Col className='mw-fc'>
        <Row className='gx-2'>
          <Col className='mw-225px'>
            <Stack
              direction='horizontal'
              className='border home-daterange-picker px-2'
            >
              <Stack direction='horizontal' className='me-1'>
                <FaCalendar color='var(--bs-gray-light)' />
              </Stack>
              <DatePicker
                locale='en'
                selected={new Date(startDate)}
                onChange={(date) => handleChangeStartDate(date)}
              />
              -
              <DatePicker
                locale='en'
                selected={new Date(endDate)}
                onChange={(date) => handleChangeEndDate(date)}
                minDate={new Date(startDate)}
              />
            </Stack>
          </Col>
          <Col className='mw-fc'>
            <Button variant='secondary' onClick={handleClickApplyButton}>
              APPLY
            </Button>
          </Col>
        </Row>
      </Col>
      {(isListActive && activeHomeTab === 'myClients') && (
        <Col>
          <Row className='align-items-center gx-2'>
            <Col className='mw-fc'>Sort By</Col>
            <Col className='mw-250px'>
              <Select {...sortBySelect} />
            </Col>
          </Row>
        </Col>
      )}
      <Col className={activeHomeTab === 'myClients' ? 'mw-fc' : ''}>
        <Row className='align-items-center justify-content-end gx-2'>
          {activeHomeTab === 'myClients' && (
            <>
            <Col className='mw-fc'>
              <Button variant='primary' style={{width: '83px'}} onClick={handleClickExportButton}>
                Export <FaChevronDown />
              </Button>
            </Col>
            <Col className='text-gray-text'>|</Col>
            </>
          )}
          <Col className='d-flex justify-content-end'>
            <ToggleButtonGroup
              type='radio'
              name='home-view-options'
              onChange={handleToggleView}
              defaultValue={'list'}
            >
              <ToggleButton
                id='tgb-home-view-1'
                variant='outline-secondary'
                value='table'
                className='shadow-none'
              >
                <FaTable />
              </ToggleButton>
              <ToggleButton
                id='tgb-home-view-2'
                variant='outline-secondary'
                value='list'
                className='shadow-none'
              >
                <FaList />
              </ToggleButton>
            </ToggleButtonGroup>
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

export default HomeFilters;
